import React from 'react';
import moment from "moment";
import GalleryImage from "./components/GalleryImage";

export const modifyData = (data, method, options) => {
  return data.map(item => {
    const imageUrl = item.imageURLs.small || item.imageURLs.original;
    const result = {
      id: item._id,
      image: (method === 'export') ? imageUrl : <GalleryImage src={imageUrl} alt=''/>,
      name: item.original.name,
      uploadedAt: moment(item.original.uploadedAt).format("DD.MM.YYYY"),
    }

    // if table
    if (method !== 'export' && options) {
      result.options = options;
      return result;
    }

    // if 'export' button
    result.__typename = item.__typename;
      return result;
  });
}