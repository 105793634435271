import React from 'react';
import styled from "styled-components";
import { colors } from "../../constants";

const StyledMessage = styled.div`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.black};
  margin-left: 16px;
`

const PageStatusMessage = ({children}) => {
  return (
    <StyledMessage>
      {children}
    </StyledMessage>
  );
}

export default PageStatusMessage;
