import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  styled
} from "@material-ui/core";
import MenuItem from '../../../UI/MenuItem';
import useAuth from "../hooks/useAuth";
import {IconProfile} from '../../../icons';
import {colors} from '../../../constants';
import Menu from '../../../UI/Menu';
import { useTranslation } from "react-i18next";

/**
 * @summary ProfileMenu React component
 * @param {Object} props React props
 * @return {React.Node} React node
 */

export const StyledIconButton = styled((props) => (
  <IconButton
    anchororigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme, isselected }) => ({
  "& .MuiSvgIcon-root": {
    color: isselected === 'true' ? colors.blue : colors.black,
  },
}));

function ProfileMenu() {
  const { logout, viewer } = useAuth();
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isProfile = history.location.pathname?.indexOf('profile') > -1;
  const { t } = useTranslation();

  if (!viewer) return null;

  return (
    <Fragment>
      <StyledIconButton
        onClick={event => {
          setMenuAnchorEl(event.currentTarget);
        }}
        isselected={isProfile.toString()}
      >
        <IconProfile/>
      </StyledIconButton>

      <Menu
        id="profile-actions-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null); // close menu
            history.push(`/profile`);
          }}
        >
          {t("common.profile")}
        </MenuItem>
        <MenuItem onClick={logout}>{t("common.sign_out")}</MenuItem>
      </Menu>
    </Fragment>
  );
}

ProfileMenu.propTypes = {
  logout: PropTypes.func,
  viewer: PropTypes.object
};

export default ProfileMenu;
