import React, { useEffect, useState } from "react";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import { Controller, useFormContext } from "react-hook-form";
import ProductSelectorWithData from "../../../package/src/ProductSelectorWithData";
import InputLabel from "../../../UI/Form/InputLabel";
import FormInput from "../../../UI/Form/FormInput";
import { Grid } from "@material-ui/core";
import Select from "../../../UI/Select/Select";
import FormNote from "../../../UI/Form/FormNote";
import { Country }  from 'country-state-city';
import { useTranslation } from "react-i18next";

const dimensionsUnitOptions = [
  {
    value: 'cm',
    name: 'cm',
  },
  {
    value: 'in',
    name: 'in',
  },
  {
    value: 'mm',
    name: 'mm',
  },
];

const weightUnitOptions = [
  {
    value: 'kg',
    name: 'kg',
  },
  {
    value: 'ft',
    name: 'ft',
  },
];

const BlockAttributes = ({productId, shopId}) => {
  const [countriesList, setCountriesList] = useState([]);

  const { t } = useTranslation();
  const [dimensions, setDimensions] = useState('');
  const [dimensionUnit, setDimensionUnit] = useState('mm');
  const [weight, setWeight] = useState('');
  const [weightUnit, setWeightUnit] = useState('kg');

  const {
    control,
    loading
  } = useFormContext();

  useEffect(() => {
    const countries = Country.getAllCountries().map(item => ({
      name: item.name,
      value: item.isoCode,
    }));
    setCountriesList(countries);
  }, [])

  return (
    <>
      <FormCard>
        <FormHeader>{t('product.origin_country')}</FormHeader>
        <InputLabel disabled={loading}>{t('product.origin_country')}</InputLabel>
        <Controller
          name="originCountry"
          control={control}
          render={({ field }) => <Select
            placeholder={t('product.choose_country')}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
            options={countriesList}
          />}
        />

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <InputLabel disabled={loading}>{t('product.dimensions')}</InputLabel>
            {/*<Controller*/}
            {/*  name="dimensions"*/}
            {/*  control={control}*/}
            {/*  render={({ field }) => <FormInput*/}
            {/*    value={field.value}*/}
            {/*    onChange={field.onChange}*/}
            {/*    disabled={loading}*/}
            {/*    margin='0'*/}
            {/*  />}*/}
            {/*/>*/}
            <FormInput
              value={dimensions}
              onChange={(e) => setDimensions(e.target.value)}
              disabled={loading}
              margin='0'
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel disabled={loading}>{t('product.unit')}</InputLabel>
            {/*<Controller*/}
            {/*  name="dimensionsUnit"*/}
            {/*  control={control}*/}
            {/*  render={({ field }) => <Select*/}
            {/*    value={field.value}*/}
            {/*    onChange={field.onChange}*/}
            {/*    options={dimensionsUnitOptions}*/}
            {/*    disabled={loading}*/}
            {/*    margin='0'*/}
            {/*  />}*/}
            {/*/>*/}
            <Select
              value={dimensionUnit}
              onChange={(e) => setDimensionUnit(e.target.value)}
              options={dimensionsUnitOptions}
              disabled={loading}
              margin='0'
            />
          </Grid>
        </Grid>

        <FormNote>{t('product.write_dimensions_example')}</FormNote>

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <InputLabel disabled={loading}>{t('product.weight')}</InputLabel>
            {/*<Controller*/}
            {/*  name="weight"*/}
            {/*  control={control}*/}
            {/*  render={({ field }) => <FormInput*/}
            {/*    value={field.value}*/}
            {/*    onChange={field.onChange}*/}
            {/*    disabled={loading}*/}
            {/*  />}*/}
            {/*/>*/}
            <FormInput
              value={weight}
              onChange={(e) =>setWeight(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel disabled={loading}>{t('product.unit')}</InputLabel>
            {/*<Controller*/}
            {/*  name="weightUnit"*/}
            {/*  control={control}*/}
            {/*  render={({ field }) => <Select*/}
            {/*    value={field.value}*/}
            {/*    onChange={field.onChange}*/}
            {/*    options={weightUnitOptions}*/}
            {/*    disabled={loading}*/}
            {/*  />}*/}
            {/*/>*/}
            <Select
              value={weightUnit}
              onChange={(e) => setWeightUnit(e.target.value)}
              options={weightUnitOptions}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </FormCard>

      <FormCard>
        <FormHeader>{t('product.related_products')}</FormHeader>
        <Controller
          name="relatedProducts"
          control={control}
          render={({ field }) => <ProductSelectorWithData
            shopIds={shopId}
            excludeIds={[productId]}
            value={field.value}
            onChange={field.onChange}
            disabled={loading}
          />}
        />
      </FormCard>
    </>
  );
}

export default BlockAttributes;
