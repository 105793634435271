import styled from "styled-components";
import { colors } from "../../constants";

const InputErrorMessage = styled.p`
  color: ${colors.redLight};
  margin: -9px 0 24px 0;
  font-family: 'Mulish', sans-serif;
  font-size: 13px;
`

export default InputErrorMessage;
