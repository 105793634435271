import React from 'react';
import styled, {css} from "styled-components";
import FormHeader from "../../../UI/Form/FormHeader";
import { colors } from '../../../constants';
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  min-width: 350px;
  max-width: 600px;
  height: 70vh;
  background: #FFFFFF;
  box-shadow: 0 7px 20px rgba(183, 195, 211, 0.25);
  border-radius: 40px;
  
  .heading {
    padding: 32px 32px 0;
    margin-bottom: 13px;
  }
  
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .hr {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
    margin: 15px 0 0;
  }
  
  .scrollArea {
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E9E9E9;
      border-radius: 8px;
    }
  }
`

const SelectAllButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.isAllHousesSelected ? colors.textGrey : colors.blue};
  padding-right: 0;
  margin-bottom: -20px;
`

const GreyText = styled.p`
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.textGrey};
  margin: 0;
  user-select: none;
`

const BlueText = styled.p`
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  color: ${colors.blue};
`

const BlackText = styled.p`
  margin: 0;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.disabled ? colors.textGrey : colors.black};
`

const disabledRowCss = css`
  background: white;

  &:hover {
    background: white;
  }
`

const WrapperTableRow = styled.div`
  width: 100%;
  padding: 11px 32px 7px;
  background: ${props => props.isSelected ? '#F3F6FF' : 'white'};
  
  &:hover {
    background: ${colors.white8};
  }
  
  ${props => props.disabled && disabledRowCss}
`

const getCss = (num) => {
  if (num > 0 && num < 5) {
    return css`
      background: rgba(223, 86, 86, 0.2);
      border-color: ${colors.redLight};
      color: ${colors.redLight};
    `
  }

  if (num >= 5) {
    return css`
      background: rgba(98, 207, 122, 0.2);
      border-color: ${colors.grass};
      color: ${colors.grass};
    `
  }
  return null;
}

const WrapperHouses = styled.div`
  width: ${props => props.num > 99 ? '33px' : '26px'};
  height: 26px;
  background: rgba(191, 191, 191, 0.2);
  border: 1px solid ${colors.textGrey};
  border-radius: 4px;
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${colors.textGrey};
  user-select: none;
  
  ${props => props.num && getCss(props.num)};
`

const HousesField = ({ num }) => {
  return (
    <WrapperHouses num={num}>
      {num}
    </WrapperHouses>
  );
}

const TableRow = ({
  isSelected,
  name,
  houseQuantity,
  isDetailed,
  handleClick,
  handleSelectAllHouses,
  isAllHousesSelected,
}) => {
  const { t } = useTranslation();

  const disabled = isDetailed && houseQuantity < 1;

  const handleClickSelect = () => {
    if (disabled) {
      return;
    }
    handleClick();
  }

  return (
    <WrapperTableRow
      isSelected={isSelected}
      onClick={handleClickSelect}
      disabled={disabled}
    >
      <div className='row'>
        <GreyText>{t('street.chosen_street')}</GreyText>
        {
          isDetailed ?
            <GreyText>{t('street.availability')}</GreyText> :
            <SelectAllButton
              onClick={() => handleSelectAllHouses(!isAllHousesSelected)}
              isAllHousesSelected={isAllHousesSelected}
            >
              {isAllHousesSelected ? t('street.deselect_all') : t('street.select_all')}
            </SelectAllButton>
        }
      </div>
      <div className='row'>
        <BlackText disabled={disabled}>{name}</BlackText>
        {
          isDetailed &&
          <HousesField num={houseQuantity}/>
        }
      </div>
    </WrapperTableRow>
  );
}

const StreetMapSelector = ({
   isDetailed,
   streetsList,
   selectedStreet,
   setSelectedStreet,
   handleSelectAllHouses,
   currentHolderPlacement,
   placesOnStreets,
   subscriptionAvailableStreets
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>

      <div className='heading'>
        <FormHeader margin='0 0 14px 0'>{t('street.choose_the_street')}</FormHeader>
        {
          isDetailed &&
          <>
            <div className='row'>
              <GreyText>{t('street.chosen_street')}</GreyText>
              <GreyText>{t('street.house_number')}</GreyText>
            </div>
            <div className='row row--second'>
              <BlueText>{(currentHolderPlacement!==null) ?
                streetsList.find(street => street.id === currentHolderPlacement.streetId)?.name || '' : ''}</BlueText>
              <BlueText>{(currentHolderPlacement!==null) ? currentHolderPlacement.placeId : ''}</BlueText>
            </div>
            <div className='hr'/>
          </>
        }
      </div>

      <div className='scrollArea'>
        {
          streetsList.filter(street => subscriptionAvailableStreets.find((s=>s === street.id))).map(street => {
            const houseQuantity = street.places.length;
            const availableHouseCount = street.places.reduce((acc, cur) => {
              return cur.status === 'none' ? acc + 1 : acc;
            }, 0);
            const foundStreet = placesOnStreets.find(item => item.street.id === street.id);
            const places = foundStreet?.places.map(place => place.id) || [];

            return <TableRow
              key={street.id}
              isDetailed={isDetailed}
              isSelected={street.id === selectedStreet}
              name={street.name}
              houseQuantity={availableHouseCount}
              handleClick={() => setSelectedStreet(street.id)}
              handleSelectAllHouses={(isSelectAll) => handleSelectAllHouses({
                streetId: street.id,
                placesIds: street.places.map(place => place.id),
                selected: isSelectAll,
              })}
              isAllHousesSelected={houseQuantity === places.length}
            />;
          })
        }
      </div>

    </Wrapper>
  );
}

export default StreetMapSelector;
