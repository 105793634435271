import React, { useContext } from "react";
import {TableHead, TableRow, TableCell, TableSortLabel} from '@material-ui/core';
import Checkbox from '../Checkbox';
import styled, { css } from "styled-components";
import {colors} from '../../constants';
import { LanguageContext } from "../../package/src/context/LanguageContext";

const StyledTableHeadCell = styled(TableCell)`
  &.MuiTableCell-head {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: ${colors.black};
    padding: 0 16px 0 0;
    text-align: left;
  }

  &:first-child {
    padding-left: ${props => props.showcheckboxes ? '16px' : '24px'};
  }
  
  .postfix {
    color: ${colors.textGrey};
  }
  
  ${props => props.isrtl === 'true' && css`
    &.MuiTableCell-head {
      padding: 0 0 0 16px;
      text-align: right;
    }

    &:first-child {
      padding-right: ${props => props.showcheckboxes ? '16px' : '24px'};
    }

    &.MuiTableCell-stickyHeader {
      left: unset;
      right: 0;
    }
  `};

  @media all and (max-width: 959px) {
    &.MuiTableCell-head {
      padding: 0;
    }
  }
`;

const StyledEmptyTableCell = styled(TableCell)`
  ${props => props.isrtl === 'true' && css`
    &.MuiTableCell-stickyHeader {
      left: unset;
      right: 0;
    }
  `};
`;

function EnhancedTableHead({
  onSelectAllClick, 
  order, 
  orderBy, 
  numSelected, 
  rowCount, 
  onRequestSort, 
  columns,
  showCheckboxes
}) {
  const { isRtl } = useContext(LanguageContext);

  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {
          showCheckboxes &&
          <StyledTableHeadCell
            padding="none"
            showcheckboxes={String(showCheckboxes)}
            isrtl={String(isRtl)}
          >
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </StyledTableHeadCell>
        }
        {columns.map(headCell => {

            if (headCell.name === 'options') {
              return <StyledEmptyTableCell
                key={headCell.name}
                isrtl={String(isRtl)}
              />;
            }

            let str = headCell.label || '';
            if (headCell.postfix) {
              str = <div>{str} <span className='postfix'>{headCell.postfix}</span></div>
            }

            if (headCell.sortable === false) {
              return <StyledTableHeadCell
                key={headCell.name}
                isrtl={String(isRtl)}
              >
                {str}
              </StyledTableHeadCell>
            }

            const sortProperty = headCell.sortable ? headCell.sortable : headCell.name;

            return <StyledTableHeadCell
              key={headCell.name}
              sortDirection={orderBy === sortProperty ? order : false}
              isrtl={String(isRtl)}
            >
              <TableSortLabel
                active={orderBy === sortProperty}
                direction={orderBy === sortProperty ? order : "asc"}
                onClick={createSortHandler(sortProperty)}
              >
                {str}
              </TableSortLabel>
            </StyledTableHeadCell>
          }
        )}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
