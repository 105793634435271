import gql from "graphql-tag";

const updateSimpleInventory = gql`
  mutation updateSimpleInventory($input: UpdateSimpleInventoryInput!){
    updateSimpleInventory(input: $input){
      inventoryInfo {
        canBackorder
        inventoryInStock
      }
    }
  }
`;

export default updateSimpleInventory;
