import React from "react";
import styled, {css} from "styled-components";
import { colors } from '../../../../constants';
import Description from "./Description";
import Button from "../../../../UI/Button/Button";
import { Icon } from "@material-ui/core";
import svgFlag from '../../../../icons/package-flag.svg';
import ActivePlan from "./ActivePlan";
import { useTranslation } from "react-i18next";

const activeCss = css`
  background: linear-gradient(204.83deg, #2352C6 -3.48%, #E988F1 106.8%);

  .head p {
    color: white !important;
  }

  p {
    color: white;
  }
`

const Wrapper = styled.div`
  width: 300px;
  min-height: 463px;
  position: relative;
  padding: 30px 30px 25px;
  background: ${colors.white6};
  border-radius: 23px;
  
  .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 23px;
    margin-bottom: 17px;

    p {
      margin: 0 0 5px 0;
    }
    
    .columnRight {
      text-align: right;
    }
    
    .title {
      font-family: 'Fredoka', sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: ${colors.black};
    }
    
    .subtitle {
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 15px;
      color: ${colors.black};
    }
    
    .duration {
      font-family: 'Mulish', sans-serif;
      font-weight: 400;
      font-size: 15px;
      color: #A5A5A5;
    }
  }
  
  .buttonContainer {
    width: 100%;
    padding: 0 30px;
    position: absolute;
    bottom: 23px;
    left: 0;
  }
  
  ${props => props.isActive && activeCss}
`

const WrappedFlag = styled(Icon)`
  position: absolute;
  top: -6px;
  right: 30px;
  height: 35px;
`

/*
Правила показа кнопки:

Если пакет активен, то показать Active plan.
Если пакет не активен:
{
  Если в режиме создания магазина, то показывать, если нет open-подписок
  Если в режиме редактирования, то показывать всегда.
  Надпись зависит от того, есть ли у юзера активная подписка.
}
 */

const PackageCard = ({data, hasActive, isDetailed, isActive, handleClick, shopId, openId}) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const {title, currency, subtitle, baseRate, duration, description} = data;

  const ButtonComponent = <Button
    width='100%'
    handleClick={handleClick}
  >
    {hasActive ? t('subscription.change_plan') : t('subscription.get_started')}
  </Button>;

  const renderButton = () => {
    if (shopId) {
      return ButtonComponent;
    } else {
      if (openId) {
        return null;
      } else {
        return ButtonComponent;
      }
    }
  };

  return (
    <Wrapper isActive={isActive}>

      <div className='head'>
        <div>
          <p className='title'>{title}</p>
          <p className='subtitle'>{subtitle}</p>
        </div>
        <div className='columnRight'>
          <p className='title'>{currency + baseRate}</p>
          <p className='duration'>{t('subscription.per_days', { duration })}</p>
        </div>
      </div>

      <Description
        rawText={description}
      />

      {
        !isDetailed &&
        <div className='buttonContainer'>
          {
            isActive ?
              <ActivePlan/> :
              renderButton()
          }
        </div>
      }
      {
        isActive && <WrappedFlag>
          <img src={svgFlag} alt=''/>
        </WrappedFlag>
      }
    </Wrapper>
  );
}

export default PackageCard;
