import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core";
import { React } from "mdi-material-ui";
import { useTranslation } from "react-i18next";

/**
 * Note that the CSS for this Component is in index.css of `App` because it
 * has to be added to `:root`
 */

const useStyles = makeStyles(theme => ({
  container: {
    width: "99%",
    height: "95vh",
  },
}));

const CustomAmplifyAuthenticator = ({ signinHeader, signupHeader }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Overwrite error messages
  I18n.putVocabularies({
    en: {
      "Custom auth lambda trigger is not configured for the user pool.": "Password cannot be empty",
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        "Password not long enough",
      "Password did not conform with policy: Password not long enough": "Password not long enough",
      "Attribute value for given_name must not be null": "First Name required",
      "Attribute value for family_name must not be null": "Last Name required",
      "Attribute value for profile must not be null": "Company Name required",
      "Username/client id combination not found.": "User not found",
    },
  });

  return (
    <div className={classes.container}>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn
          slot="sign-in"
          headerText={signinHeader || t('common.sign_in_to_account')}
          usernameAlias="email"
          hideSignUp
        />
      </AmplifyAuthenticator>
    </div>
  );
};

export default CustomAmplifyAuthenticator;
