import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PrimaryAppBar from "../../../package/src/PrimaryAppBar/PrimaryAppBar";
import { Container } from "@material-ui/core";
import useProduct from "../hooks/useProduct";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductSchema } from "../schemas";
import { Form } from '../../../UI/Form/MyForm';
import BlockMain from "../blocks/BlockMain";
import StyledButton from "../../../UI/Button/Button";
import SaveButtons from "../../../UI/Button/SaveButtons";
import BlockSide from "../blocks/BlockSide";
import Grid from "@material-ui/core/Grid";
import BlockDetails from "../blocks/BlockDetails";
import { slugify } from "transliteration";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { updateProductCategoriesMutation } from "../../../package/src/CategorySelectorWithData/graphql/mutations";
import { updateProductBrandsMutation } from "../../../package/src/BrandSelectorWithData/graphql/mutations";
import BlockAttributes from "../blocks/BlockAttributes";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import Dialog from "../../../UI/Dialog/Dialog";
import createProductMutation from "../graphql/mutations/createProduct";
import getSimpleInventory from "../graphql/queries/inventory";
import updateSimpleInventory from "../graphql/mutations/updateInventory";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: 1100px;
`

const defaultValues = {
  // block 'main'
  productName: '',
  productSubtitle: '',
  productShortDescription: '',
  productLongDescription: '',
  productNotes: '',

  // block 'side'
  status: 'invisible',
  categories: [],
  facebook: '',
  twitter: '',
  direct: '',
  tags: [],

  // block 'details'
  brand: null,
  price: 0,
  compareAtPrice: 0,
  sku: '',
  enableInventory: false,
  allowBackorders: false,
  trackQuantity: 0,

  // block 'attributes'

  originCountry: '',
  // dimensions: '',
  // dimensionsUnit: 'cm',
  // weight: '',
  // weightUnit: 'kg',
  relatedProducts: [],
}

const ProductDetail = ({ match, history, viewer }) => {
  const shopId = match?.params.shopId;
  const resetFormRef = useRef(true); // solves the problem with updating form fields after updating images
  const refSubmit = useRef(null);
  const productId = match?.params.productId;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productVariantId, setProductVariantId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const methods = useForm({
    method: 'onChange',
    resolver: yupResolver(ProductSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    // solves the problem with the enabled "save changes" button after submit
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful])

  const {
    product,
    onPublishProduct,
    onUpdateProduct,
    onUpdateProductVariantPrices,
    onUpdateProductVariant,
    onUpdateRelatedProducts,
    refetchProduct,
    isLoading: loading,
    isPublished,
  } = useProduct({ productId, shopId });

  const refetch = () => {
    if (refetchProduct) {
      resetFormRef.current = true;
      refetchProduct();
    }
  }

  const [createProduct] = useMutation(createProductMutation, {
    onError: (e) => console.log(e.message),
  })

  const [mutateProductCategories] = useMutation(updateProductCategoriesMutation, {
    ignoreResults: true,
    onError: (e) => console.log(e.message),
  })

  const [mutateProductBrands] = useMutation(updateProductBrandsMutation, {
    ignoreResults: true,
    onError: (e) => console.log(e.message),
  })

  const [mutateInventory] = useMutation(updateSimpleInventory, {
    ignoreResults: true,
    onError: (e) => console.log(e.message),
  })

  const getInventory = (id) => {
    return apolloClient.query({
      query: getSimpleInventory,
      variables: {
        shopId,
        productConfiguration: {
          productId,
          productVariantId: id,
        }
      },
      fetchPolicy: "network-only",
    })
  }

  useEffect(() => {
    if (resetFormRef.current && product) {
      const { compareAtPrice, price } = product.variants[0].pricing;
      const variantId = product.variants[0]._id;
      setProductVariantId(variantId);

      reset({
        productName: product.title || '',
        productSubtitle: product.pageTitle || '',
        productShortDescription: product.metaDescription || '',
        productLongDescription: product.description || '',
        productNotes: product.purchaseNotes || '',
        status: product.isVisible ? 'visible' : 'invisible',
        categories: product.productCategories.map(item => item._id),
        facebook: product.facebookMsg,
        twitter: product.twitterMsg,
        direct: product.directMsg,
        tags: product.tags.nodes.map(item => item._id),
        brand: product.productBrands[0]?._id || null,
        originCountry: product.originCountry || '',
        relatedProducts: product.relatedProducts.map(item => item._id),
        price: price || 0,
        compareAtPrice: compareAtPrice?.amount || 0,
        sku: product.variants[0].sku || '',
      });

      getInventory(variantId)
        .then((res) => {
          const data = res.data.simpleInventory;
          if (data) {
            setValue('enableInventory', Boolean(data.isEnabled));
            setValue('allowBackorders', Boolean(data.canBackorder));
            setValue('trackQuantity', data.inventoryInStock || 0);
          }
        })
        .catch((e) => {
          console.log(e.message);
        })
        .finally(() => {
          resetFormRef.current = false;
        });
    }
  }, [product])

  const onSubmit = async(formData) => {
    // const shouldConformSitemapGenerate =
    //   formData.shouldAppearInSitemap !== product.shouldAppearInSitemap &&
    //   formData.isVisible &&
    //   !formData.isDeleted;

    setIsSubmitting(true);

    const companyName = viewer?.adminUIShops.find(shop => shop._id === shopId)?.company?.name;
    let myProductId = productId;
    let myProductVariantId = productVariantId;
    let needReloadPage = false;

    if (!myProductId) {
      const { data } = await createProduct({
        variables: {
          input: {
            shopId,
            product: {
              isVisible: false,
            },
          },
        },
      });

      if (data) {
        myProductId = data.createProduct.product._id;
        myProductVariantId = data.createProduct.product.variants[0]._id;
        needReloadPage = true;
      }
    }

    if (!myProductId) {
      setIsSubmitting(false);
      console.error('Product id not found');
      return;
    }

    Promise.all([
      onUpdateProduct({
        product: {
          title: formData.productName.trim(),
          pageTitle: formData.productSubtitle,
          metaDescription: formData.productShortDescription,
          description: formData.productLongDescription,
          purchaseNotes: formData.productNotes,
          isVisible: formData.status === 'visible',
          facebookMsg: formData.facebook,
          twitterMsg: formData.twitter,
          directMsg: formData.direct,
          tagIds: formData.tags,
          slug: slugify(formData.productName),
          vendor: companyName || '',
          originCountry: formData.originCountry,
        },
        productId: myProductId,
      }),

      onUpdateProductVariantPrices({
        variantPrices: {
          price: formData.price,
          compareAtPrice: {
            amount: formData.compareAtPrice,
          },
        },
        variantId: myProductVariantId,
      }),

      onUpdateProductVariant({
        variant: {
          attributeLabel: 'Default attribute label',
          sku: formData.sku.trim(),
          optionTitle: formData.productName,
        },
        variantId: myProductVariantId,
      }),

      mutateProductCategories({
        variables: {
          productId: myProductId,
          shopId,
          productCategoryIds: formData.categories,
        },
      }),

      mutateProductBrands({
        variables: {
          productId: myProductId,
          shopId,
          productBrandIds: [formData.brand],
        },
      }),

      mutateInventory({
        variables: {
          input: {
            shopId,
            productConfiguration: {
              productId: myProductId,
              productVariantId: myProductVariantId,
            },
            canBackorder: formData.allowBackorders,
            inventoryInStock: formData.trackQuantity,
            isEnabled: formData.enableInventory,
          }
        }
      }),

      onUpdateRelatedProducts({
        productId: myProductId,
        relatedProductIds: formData.relatedProducts,
      }),
    ])
      .then(() => {
        if (needReloadPage) {
          history.push(`/products/edit/${shopId}/${myProductId}`);
        } else {
          refetch();
          setIsSubmitting(false);
        }

        // if (shouldConformSitemapGenerate) {
        //   openGenerateSitemapsConfirmDialog();
        // }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      })
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitWithinDialog = () => {
    refSubmit?.current.click();
    handleCloseDialog();
  };

  return (
    <>
      <PrimaryAppBar
        title={productId ? t('product.edit_product') : t('product.create_product')}
        onBackButtonClick={() => {
          history.push('/products');
        }}
      />

      <StyledContainer>
        <FormProvider
          {...methods}
          loading={loading || isSubmitting}
          product={product}
          handleOpenDialog={handleOpenDialog}
        >
          <Form
            width='100%'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={7}>
                <BlockMain />
                <BlockDetails
                  productId={productId}
                  shopId={shopId}
                />
                <BlockAttributes
                  productId={productId}
                  shopId={shopId}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <BlockSide
                  shopId={shopId}
                />
              </Grid>
            </Grid>

            <SaveButtons margin='24px 0' justifyContent='flex-end'>
              <StyledButton
                width='180px'
                type="submit"
                disabled={!isDirty || loading || isSubmitting}
                ref={refSubmit}
              >
                {productId ? t('ui.save_changes') : t('product.create_product')}
              </StyledButton>
              <StyledButton
                width='180px'
                mytype='third'
                disabled={loading || isPublished || isSubmitting}
                handleClick={onPublishProduct}
              >
                {isPublished ? t('product.published') : t('ui.publish')}
              </StyledButton>
            </SaveButtons>
          </Form>
        </FormProvider>
      </StyledContainer>

      <Dialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
      >
        <DialogMessages
          title={t('ui.warning')}
          message={t('product.you_must_create_product')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={handleCloseDialog}
          >
            {t('ui.no')}
          </StyledButton>
          <StyledButton
            width='180px'
            handleClick={handleSubmitWithinDialog}
          >
            {t('ui.yes')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </>
  );
}

export default ProductDetail;
