import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import BillingCard from "../../../../plugins/settings/components/BillingCard";
import DialogButtons from "../../../../UI/Dialog/DialogButtons";
import Button from "../../../../UI/Button/Button";
import { useQuery } from "@apollo/react-hooks";
import { stripeListPaymentMethodsQuery } from "../../../../plugins/settings/graphql/queries";
import { BillingCardsBox, LoadingBox } from "../styles";
import Dialog from "../../../../UI/Dialog/Dialog";
import BillingForm from "../../../../plugins/settings/components/BillingForm";
import { difference } from "lodash";
import { useTranslation } from "react-i18next";

const Billing = ({ handleProceedPayment, companyId, isFreePackage }) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [payments, setPayments] = useState(null);

  const oldData = useRef([]);

  const { data, loading, refetch } = useQuery(stripeListPaymentMethodsQuery, {
    variables: {
      input: {
        companyId,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const payments = data?.stripeListPaymentMethods || [];

    // при добавлении новой карты автоматически выделять её в меню
    const ids = payments.map((k) => k.id);
    const oldIds = oldData.current.map((k) => k?.id).filter((d) => d);
    const newIds = difference(ids, oldIds);

    if (newIds.length === 1) {
      setSelectedId(newIds[0]);
    } else {
      // в противном случае, выделить default карту, если она есть
      const defaultCardId = payments.find((k) => k.isDefault)?.id;
      if (defaultCardId) {
        setSelectedId(defaultCardId);
      }
    }

    setPayments(payments);
    oldData.current = payments;
  }, [data]);

  if (loading) {
    return <LoadingBox>
      <CircularProgress />
    </LoadingBox>;
  }

  const handleAddCard = () => {
    setDialogOpen(true);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <BillingCardsBox>
        {
          payments?.map(card => <BillingCard
            key={card.id}
            brand={card.brand}
            isDefault={card.isDefault}
            cardNumber={card.lastFourDigits}
            handleClick={() => setSelectedId(card.id)}
            isSelected={card.id === selectedId}
            whiteBackground
          />)
        }
      </BillingCardsBox>

      <DialogButtons
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          padding: '0 26px 26px',
          gap: '10%',
        }}
      >
        <Button
          width='40%'
          handleClick={handleAddCard}
        >
          {t('payment.add_card')}
        </Button>
        <Button
          width='60%'
          handleClick={() => handleProceedPayment(selectedId)}
          disabled={!selectedId && !isFreePackage}
        >
          {t('payment.proceed_payment')}
        </Button>
      </DialogButtons>

      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        <BillingForm
          cardId={selectedId}
          refetchPayments={refetch}
          closeDialog={closeDialog}
          companyId={companyId}
        />
      </Dialog>
    </>
  );
};

export default Billing;
