import gql from "graphql-tag";
import { Shop, ShopAfterCreation } from "./fragments";

export const createShopMutation = gql`
  mutation createShop($input: CreateShopInput!) {
    createShop(input: $input) {
      shop {
        ${ShopAfterCreation}
      }
    }
  }
`;

export const updateShopMutation = gql`
  mutation updateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      shop {
        ${ShopAfterCreation}
      }
    }
  }
`;

export const removeShopMutation = gql`
  mutation removeShop($id: ID!) {
    removeShop(id: $id) {
      shop {
        ${Shop}
      }
    }
  }
`;

export const addMerchantRequestMutation = gql`
  mutation addMerchantRequest($input: AddMerchantRequestInput!) {
    addMerchantRequest(input: $input) {
      _id
    }
  }
`;

export const removeMerchantRequestMutation = gql`
  mutation removeMerchantRequest($id: ID!) {
    removeMerchantRequest(id: $id) {
      _id
    }
  }
`;

export const addProductCategoryMutation = gql`
  mutation AddProductCategory($name: String!) {
    addProductCategory(name: $name) {
      _id
      name
    }
  }
`;

export const addProductBrandMutation = gql`
  mutation AddProductBrand($name: String!) {
    addProductBrand(name: $name) {
      _id
      name
    }
  }
`;

export const setShopSubscriptionOverrides = gql`
  mutation setShopSubscriptionOverrides(
    $id: ID!,
    $rateOverride: OverrideByPercentageInput,
    $commissionOverride: OverrideByNewValueInput,
  ) {
    setShopSubscriptionOverrides(
      id: $id,
      rateOverride: $rateOverride,
      commissionOverride: $commissionOverride
    ) {
      rate {
        changePercentage
        startDate
        endDate
        disabled
        notes
      }
      commission {
        newValue
        startDate
        endDate
        disabled
        notes
      }
    }
  }
`;

export const approveShop = gql`
  mutation approveShop($shopId: ID) {
    approveShop(shopId: $shopId)
  }
`;

export const banShop = gql`
  mutation banShop($shopId: ID) {
    banShop(shopId: $shopId)
  }
`;

export const declineShop = gql`
  mutation declineShop($shopId: ID) {
    declineShop(shopId: $shopId)
  }
`;

export const sendShopToApprove = gql`
  mutation sendShopToApprove($shopId: ID) {
    sendShopToApprove(shopId: $shopId)
  }
`;

export const publishShop = gql`
  mutation publishShop($shopId: ID) {
    publishShop(shopId: $shopId)
  }
`;

export const addSubscription = gql`
  mutation addSubscription($id: ID!) {
    addSubscription(id: $id) {
      subscription {
        _id
        subscriptionPackageId
        createdAt
        status
        shopId
      }
    }
  }
`;

export const switchSubscription = gql`
  mutation switchSubscription($input: SwitchSubscriptionInput) {
    switchSubscription(input: $input)
  }
`;

export const enablePaymentMethodForShop = gql`
mutation enablePaymentMethodForShop($input: EnablePaymentMethodForShopInput!) {
  enablePaymentMethodForShop(input: $input) {
    paymentMethods {
      name
      isEnabled
    }
  }
}
`;

export const createFlatRateFulfillmentMethod = gql`
mutation createFlatRateFulfillmentMethod($input: CreateFlatRateFulfillmentMethodInput!) {
  createFlatRateFulfillmentMethod(input: $input) {
    method {
      _id
    }
  }
}
`;
