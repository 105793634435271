import React, { useMemo, useState } from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "../AppBar";
import ProfileMenu from "../ProfileMenu";
import NavigationDrawer from "../NavigationDrawer";
import Routes from "../Routes";
import UIContext from "../context/UIContext";
import useMediaQuery from "../hooks/useMediaQuery";
import useRoutes from "../hooks/useRoutes";
import useAuth from "../hooks/useAuth";
import filterRoutesBasedOnAccountType from "../lib/core/filterRoutesBasedOnAccountType";
import { IconSettings } from "../../../icons";
import { StyledIconButton } from "../ProfileMenu/ProfileMenu";
import { useHistory } from "react-router-dom";
import LanguageSwitch from "../LanguageSwitch";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    '& header': {
      borderBottom: 'none',
    }
  },
  centeredContainer: {
    display: "flex",
    paddingTop: "30%",
    justifyContent: "center",
  },
  leftSidebarOpen: {
    ...theme.mixins.leadingPaddingWhenPrimaryDrawerIsOpen,
  },
  menuPadding: {
    width: 410,
    flexShrink: 0,
  },
}));

/**
 * Main dashboard layout
 * @returns {React.ReactElement} A react element representing the main dashboard
 */
function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("mobile");
  const { viewer } = useAuth();
  let routes = useRoutes();

  const [isDetailDrawerOpen, setDetailDrawerOpen] = useState(false);
  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);

  // Not all users have permission to see all routes
  routes = filterRoutesBasedOnAccountType(routes, viewer);

  const navigationRoutes = routes.filter(route => route?.group === "navigation");

  const onToggleNavigationDrawer = () => {
    setNavigationDrawerOpen(prevValue => !prevValue);
  };

  const onToggleDetailDrawer = () => {
    setDetailDrawerOpen(prevValue => !prevValue);
  };

  const onCloseDetailDrawer = () => {
    setDetailDrawerOpen(false);
  };

  const onCloseNavigationDrawer = () => {
    setNavigationDrawerOpen(false);
  };

  const contextValue = useMemo(
    () => ({
      isDetailDrawerOpen,
      isMobile,
      isNavigationDrawerOpen: (isMobile && isNavigationDrawerOpen) || !isMobile,
      onCloseNavigationDrawer,
      onToggleNavigationDrawer,
      onCloseDetailDrawer,
      onToggleDetailDrawer,
      setDetailDrawerOpen,
      setNavigationDrawerOpen,
    }),
    [isDetailDrawerOpen, isMobile, isNavigationDrawerOpen]
  );

  const Body = () => {
    // Do not load any Route until viewer is ready (i.e. is set and contains id)
    // because some routes depend on viewer
    if (!viewer || !viewer?._id) {
      return (
        <div className={classes.centeredContainer}>
          <CircularProgress />
        </div>
      );
    }

    // Shopper should not see anything except an error telling him he does not
    // have access to the admin panel
    if (viewer.type === "shopper") {
      return <h1 className={classes.centeredContainer}>{t('common.nothing_here')}</h1>;
    }

    // Not all users have permission to see all routes
    // const settingsRoutes = filterRoutesBasedOnAccountType(useRoutes({ groups: ["settings"] }), viewer);

    return (
      <div className={classes.container}>
        {
          !routes.hideHeader &&
          <AppBar>
            <LanguageSwitch />
            <StyledIconButton
              onClick={() => history.push('/settings')}
            >
              <IconSettings/>
            </StyledIconButton>
            <ProfileMenu size={40} />
          </AppBar>
        }
        <NavigationDrawer routes={navigationRoutes} viewer={viewer} />
        <Hidden smDown>
          <div className={classes.menuPadding}/>
        </Hidden>
        <Routes isExactMatch={true} routes={routes} viewer={viewer} />
      </div>
    );
  };

  return (
    <UIContext.Provider value={contextValue}>
      <CssBaseline />
      <Body />
    </UIContext.Provider>
  );
}

export default Dashboard;
