import React, { Fragment, useState, useMemo, useCallback, useContext, useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "@reactioncommerce/catalyst/Button";
import moment from "moment";
import { useSnackbar } from "../../../package/src";
import { useDropzone } from "react-dropzone";
import { Grid, makeStyles, Hidden } from "@material-ui/core";
import productsQuery from "../graphql/queries/products";
import publishProductsToCatalog from "../graphql/mutations/publishProductsToCatalog";
import archiveProducts from "../graphql/mutations/archiveProducts";
import updateProduct from "../graphql/mutations/updateProduct";
import cloneProducts from "../graphql/mutations/cloneProducts";
import createProductMutation from "../graphql/mutations/createProduct";
import bulkImportMutation from "../graphql/mutations/bulkImport";
import TagSelector from "../components/TagSelector";
import useCurrentShop from "../../../package/src/hooks/useCurrentShop";
import Toolbar from "../../../package/src/Toolbar";
import StyledButton from '../../../UI/Button';
import Dropdown from '../../../UI/Dropdown';
import Search from '../../../UI/Search';
import DataTable from '../../../UI/DataTable';
import Status from '../../../UI/Status';
import svgEdit from '../../../icons/table-edit.svg';
import svgApprove from '../../../icons/table-approve.svg';
import svgDelete from '../../../icons/delete.svg';
import Dialog from '../../../UI/Dialog';
import FormHeader from '../../../UI/Form/FormHeader';
import { colors, constants, standardPageStyles } from "../../../constants";
import BulkImportCard from '../components/BulkImportCard';
import {
  DialogDelete,
  DialogDeleteSingle, DialogDuplicate, DialogMakeHidden,
  DialogMakeHiddenSingle,
  DialogMakeVisible,
  DialogMakeVisibleSingle,
  DialogPublish,
} from "../components/Dialogs";
import { handleBulkExport, importFiles } from "../utils";
import { LanguageContext } from "../../../package/src/context/LanguageContext";
import { useProductColumns } from "../hooks/useProductColumns";

const useStyles = makeStyles(theme => ({
  ...standardPageStyles,
  redText: {
    color: colors.red,
    fontWeight: 700,
  }
}));

const CSV_FILE_TYPES = [
  "text/csv",
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
];

/**
 * @summary Main products view
 * @name ProductsTable
 * @returns {React.Component} A React component
 */
function ProductsTable({ selectedShopIds: shopIds, viewer }) {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [createProduct, { error: createProductError }] = useMutation(createProductMutation);
  const [bulkImport] = useMutation(bulkImportMutation);
  const { t } = useTranslation();
  const classes = useStyles();
  const { isRtl } = useContext(LanguageContext);
  const rtl = isRtl ? 'Rtl' : '';
  const columns = useProductColumns();
  const { shop: currentShop } = useCurrentShop(shopIds[0]);

  // React-Table state
  const [isLoading, setIsLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [reloadTable, setReloadTable] = useState(false);
  const [dialogName, setDialogName] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Filter by file state
  const [files, setFiles] = useState([]);
  const [isFiltered, setFiltered] = useState(false);

  const onFetchData = useCallback(({ globalFilter, pageIndex, pageSize, sortOrder, sortBy }) => {
    if (shopIds.length === 0) {
      return;
    }

    setIsLoading(true);
    apolloClient.query({
      query: productsQuery,
      variables: {
        shopIds,
        query: globalFilter,
        first: pageSize,
        limit: (pageIndex + 1) * pageSize,
        offset: pageIndex * pageSize,
        sortOrder,
        sortBy,
      },
      fetchPolicy: "network-only",
    })
      .then(res => {
        // Update the state with the fetched data as an array of objects and the calculated page count
        const total = res.data.productsWithFilters.totalCount;
        setTableData(res.data.productsWithFilters.nodes);
        setPageCount(Math.ceil(total / pageSize));
        setTotalCount(total);
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setIsLoading(false))
  }, [shopIds]);

  const refetch = () => {
    setReloadTable(prev => !prev);
  }

  const persistantSnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      persist: true,
      action: key => (
        <Fragment>
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}
            style={{ color: "white" }}
          >
            X
          </Button>
        </Fragment>
      ),
    });
  };

  const getShopId = (rowId) => tableData.find(item => item._id === rowId).shop._id;

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const productOptions = useMemo(() => [
    {
      label: t('product.edit_product'),
      value: constants.OPTIONS.editProduct,
      icon: svgEdit,
      handleOptionClick: (rowId) => {
        const shopId = getShopId(rowId);
        history.push(`/products/edit/${shopId}/${rowId}`);
      },
    },
    {
      label: t('product.make_visible'),
      value: constants.OPTIONS.makeVisible,
      icon: svgApprove,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('makeVisibleSingle');
        setDialogOpen(true);
      },
    },
    {
      label: t('product.make_hidden'),
      value: constants.OPTIONS.makeHidden,
      icon: svgApprove,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('makeHiddenSingle');
        setDialogOpen(true);
      },
    },
    {
      label: t('ui.delete'),
      value: constants.OPTIONS.delete,
      icon: svgDelete,
      handleOptionClick: (rowId) => {
        setRowId(rowId);
        setDialogName('deleteSingle');
        setDialogOpen(true);
      },
    },
  ], [tableData, t]);

  const dataProps = useMemo(() => tableData.map(item => ({
      id: item._id,
      shopName: item.shop.name,
      productId: item._id,
      productName: item.title,
      status: <Status
        type={item.isVisible ? 'visible' : 'invisible'}
        content={item.isVisible ? t('product.visible') : t('product.invisible')}
      />,
      price: item.price.range,
      createdAt: moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      options: productOptions,
      modifyOptions: (list) => {
        const valueToHide = item.isVisible ? constants.OPTIONS.makeVisible : constants.OPTIONS.makeHidden;
        return list.filter(option => option.value !== valueToHide);
      }
    })
  ), [tableData, t]);

  const onDrop = accepted => {
    if (accepted.length === 0) return;
    setFiles(accepted);
  };

  const handleCreateProduct = useCallback(async() => {
    if (shopIds.length !== 1) {
      enqueueSnackbar(t('product.please_select_one_shop'), { variant: "error" });
      return;
    }

    // const { data } = await createProduct({
    //   variables: {
    //     input: {
    //       shopId: shopIds[0],
    //       product: {
    //         isVisible: false,
    //       },
    //     },
    //   },
    // });
    //
    // if (data) {
    //   const productId = data.createProduct.product._id;
    //   history.push(`/products/create/${shopIds[0]}/${productId}`);
    // }
    //
    // if (createProductError) {
    //   enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
    // }

    history.push(`/products/create/${shopIds[0]}`);
  }, [shopIds, createProductError, createProduct, t])

  // Filter by file event handlers
  const { getRootProps, getInputProps } = useDropzone({
    accept: CSV_FILE_TYPES,
    disableClick: true,
    disablePreview: true,
    multiple: false,
    onDrop,
  });

  const handleDeleteUploadedFile = deletedFilename => {
    const newFiles = files.filter(file => file.name !== deletedFilename);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      setFiltered(false);
    } else if (isFiltered) {
      importFiles({
        newFiles,
        setIsImportLoading,
        bulkImport,
        shopId: shopIds[0],
        viewer,
        persistantSnackbar,
        refetch,
        t,
      });
    }
  };

  const mutateProductStatus = (value, productId, shopId) => {
    return apolloClient.mutate({
      mutation: updateProduct,
      variables: {
        input: {
          product: {
            isVisible: value,
          },
          productId,
          shopId,
        },
      },
    });
  }

  const deleteProduct = (productId, shopId) => {
    return apolloClient.mutate({
      mutation: archiveProducts,
      variables: {
        input: {
          productIds: [productId],
          shopId,
        },
      },
    });
  }

  const itemsListBulk = [
    {
      content: t('product.import_products'),
      action: () => {
        if (shopIds.length !== 1) {
          enqueueSnackbar(t('product.please_select_one_shop'), { variant: "error" });
          return;
        }

        setDialogName('bulkImport');
        setDialogOpen(true);
      },
    },
    {
      content: t('product.export_products'),
      action: () => handleBulkExport({
        shopIds,
        enqueueSnackbar,
        setIsImportLoading,
        apolloClient,
        persistantSnackbar,
        currentShop,
        t,
      }),
    },
  ]

  const itemsListActions = useMemo(() => [
    {
      content: t('ui.publish'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('publish');
        setDialogOpen(true);
      },
    },
    {
      content: t('ui.make_visible'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('makeVisible');
        setDialogOpen(true);
      },
    },
    {
      content: t('ui.make_hidden'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('makeHidden');
        setDialogOpen(true);
      },
    },
    {
      content: t('ui.duplicate'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('duplicate');
        setDialogOpen(true);
      },
    },
    {
      content: t('ui.add_remove_tags'),
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('tags');
        setDialogOpen(true);
      },
    },
    {
      content: <span className={classes.redText}>{t('ui.delete')}</span>,
      isDisabled: selectedRows.length === 0,
      action: () => {
        setDialogName('delete');
        setDialogOpen(true);
      },
    },
  ], [selectedRows, t])

  const handleSearch = (value) => {
    setSearchValue(value.trim());
  }

  const renderBulkAndCreate = (myClass) =>
    <Grid item xs={12} lg={5} className={classes[myClass]}>
      <Dropdown
        title={t('product.import_export_products')}
        itemsList={itemsListBulk}
      />
      <StyledButton
        minwidth='160px'
        width='220px'
        handleClick={handleCreateProduct}
      >
        {t('product.create_product')}
      </StyledButton>
    </Grid>

  // DIALOG ACTIONS

  const actionPublish = useCallback(() => {
    closeDialog();
    apolloClient.mutate({
      mutation: publishProductsToCatalog,
      variables: {
        productIds: selectedRows,
      },
    })
      .then(res => {
        refetch();
        enqueueSnackbar(
          t('product.publish_success', { count: res.data.publishProductsToCatalog.length }),
          { variant: "success" }
        );
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
        console.log(e.message);
      })
  }, [selectedRows, t])

  const actionMakeVisibleSingle = useCallback(() => {
    closeDialog();
    mutateProductStatus(true, rowId, getShopId(rowId))
      .then(res => {
        if (res) {
          refetch();
          enqueueSnackbar(t("product.visible_success", {count: 1}), { variant: "success" });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
        console.log(e.message);
      })
  }, [rowId, t])

  const actionMakeHiddenSingle = useCallback(() => {
    closeDialog();
    mutateProductStatus(false, rowId, getShopId(rowId))
      .then(res => {
        if (res) {
          refetch();
          enqueueSnackbar(t("product.hidden_success", {count: 1}), { variant: "success" });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
        console.log(e.message);
      })
  }, [rowId, t])

  const actionDeleteSingle = useCallback(() => {
    closeDialog();
    deleteProduct(rowId, getShopId(rowId))
      .then(res => {
        if (res) {
          refetch();
          enqueueSnackbar(t("product.deleted_success", {count: 1}), { variant: "success" });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
        console.log(e.message);
      })
  }, [rowId, t])

  const actionMakeVisible = useCallback(async() => {
    closeDialog();
    const successes = [];

    try {
      for (const productId of selectedRows) {
        const { data } = await mutateProductStatus(true, productId, getShopId(productId));
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
      return;
    }

    if (successes.length) {
      refetch();
      enqueueSnackbar(t("product.visible_success", { count: successes.length }), { variant: "success" })}
  }, [selectedRows, t])

  const actionMakeHidden = useCallback(async() => {
    closeDialog();
    const successes = [];
    try {
      for (const productId of selectedRows) {
        const { data } = await mutateProductStatus(false, productId, getShopId(productId));
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
      return;
    }

    if (successes.length) {
      refetch();
      enqueueSnackbar(t("product.hidden_success", { count: successes.length }), { variant: "success" });
    }
  }, [selectedRows, t])

  const actionDuplicate = useCallback(async() => {
    closeDialog();
    const successes = [];

    try {
      for (const productId of selectedRows) {
        const { data } = await apolloClient.mutate({
          mutation: cloneProducts,
          variables: {
            input: {
              productIds: [productId],
              shopId: getShopId(productId),
            },
          },
        });
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
      return;
    }

    if (successes.length) {
      refetch();
      enqueueSnackbar(t("product.duplicated_success", { count: successes.length}), {variant: 'success'});
    }
  }, [selectedRows, t])

  const DialogTags = useMemo(() => {
    return (
      <>
        <FormHeader>{t("products.add_remove_tags")}</FormHeader>
        <TagSelector
          selectedProductIds={selectedRows}
          shopIds={shopIds}
          closeDialog={closeDialog}
        />
      </>
    );
  }, [t, selectedRows, shopIds]);

  const actionDelete = useCallback(async() => {
    closeDialog();
    const successes = [];

    try {
      for (const productId of selectedRows) {
        const { data } = await deleteProduct(productId, getShopId(productId));
        if (data) successes.push(data);
      }
    } catch(e) {
      enqueueSnackbar(t('snackbar.common_error'), { variant: "error" });
      console.log(e.message);
      return;
    }

    if (successes.length) {
      refetch();
      enqueueSnackbar(t("product.deleted_success", { count: successes.length}), {variant: 'success'});
    }
  }, [selectedRows])

  const DialogBulkImport = (props) => {
    return (
      <>
        <FormHeader>{t('product.bulk_import')}</FormHeader>
        <BulkImportCard
          message={t('product.upload_csv_message')}
          importFiles={(files) => importFiles({
            newFiles: files,
            setIsImportLoading,
            bulkImport,
            shopId: shopIds[0],
            viewer,
            persistantSnackbar,
            refetch
          })}
          {...props}
        />
      </>
    );
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'publish': return <DialogPublish
        confirmAction={actionPublish}
        declineAction={closeDialog}
        selectedRows={selectedRows}
      />
      case 'makeVisibleSingle': return <DialogMakeVisibleSingle
        confirmAction={actionMakeVisibleSingle}
        declineAction={closeDialog}
      />
      case 'makeHiddenSingle': return <DialogMakeHiddenSingle
        confirmAction={actionMakeHiddenSingle}
        declineAction={closeDialog}
      />
      case 'deleteSingle': return <DialogDeleteSingle
        confirmAction={actionDeleteSingle}
        declineAction={closeDialog}
      />
      case 'makeVisible': return <DialogMakeVisible
        confirmAction={actionMakeVisible}
        declineAction={closeDialog}
        selectedRows={selectedRows}
      />
      case 'makeHidden': return <DialogMakeHidden
        confirmAction={actionMakeHidden}
        declineAction={closeDialog}
        selectedRows={selectedRows}
      />
      case 'duplicate': return <DialogDuplicate
        confirmAction={actionDuplicate}
        declineAction={closeDialog}
        selectedRows={selectedRows}
      />
      case 'tags': return DialogTags;
      case 'delete': return <DialogDelete
        confirmAction={actionDelete}
        declineAction={closeDialog}
        selectedRows={selectedRows}
      />
      case 'bulkImport': return DialogBulkImport({
        files,
        getInputProps,
        getRootProps,
        handleDelete: handleDeleteUploadedFile,
        isImportLoading,
      });
      default: return null;
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Toolbar title={t('product.product_list')} />

        {
          shopIds.length !== 0 && <>
            <Hidden mdDown>
              <Grid container className={classes.grid}>
                <Grid item lg={7} className={classes.gridEmpty}/>
                {renderBulkAndCreate(`secondColumn${rtl}`)}
              </Grid>
            </Hidden>

            <Hidden lgUp>
              {renderBulkAndCreate('secondColumnXS')}
            </Hidden>

            <div className={classes.secondRow}>
              <Dropdown
                title={t('product.actions')}
                itemsList={itemsListActions}
              />
              <Search
                handleChange={handleSearch}
                onSearchClick={refetch}
                placeholder={t('product.search_by_name')}
                useDebounce
              />
            </div>
          </>
        }

        <Grid item sm={12}>
          {shopIds.length === 0 ? (
              <span className={classes.selectSomeShops}>{t('product.please_select_shops')}</span>
            ) :
            <DataTable
              columns={columns}
              isLoading={isLoading}
              data={dataProps}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              handleFetchData={onFetchData}
              pageCount={pageCount}
              totalCount={totalCount}
              searchValue={searchValue}
              reloadTable={reloadTable}
              defaultSortField='createdAt'
              defaultSortOrder='desc'

              // styles for 2 rows upper panel + checkboxes
              maxHeight='calc(100vh - 390px)'
              maxHeightLaptop='calc(100vh - 460px)'
              maxHeightMobile='calc(100vh - 440px)'
            />
          }
        </Grid>

      </Grid>
      <Dialog
        open={dialogOpen}
        handleClose={closeDialog}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default ProductsTable;
