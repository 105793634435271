import React from "react";
import { Icon } from "@material-ui/core";
import svgCompleted from "../../../../icons/payment-completed.svg";
import FormText from "../../../../UI/Form/FormText";
import Button from "../../../../UI/Button/Button";
import { PendingContainer } from "../styles";
import { useTranslation } from "react-i18next";

const Completed = ({ handleCreateShop }) => {
  const { t } = useTranslation();

  return (
    <PendingContainer>
      <Icon className='statusIcon'>
        <img src={svgCompleted} alt=''/>
      </Icon>
      <p className='statusText'>{t('payment.payment_was_complete')}</p>
      <FormText>{t('payment.payment_complete_message')}</FormText>
      <Button
        width='200px'
        handleClick={handleCreateShop}
      >
        {t('payment.create_shop')}
      </Button>
    </PendingContainer>
  );
};

export default Completed;
