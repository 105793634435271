import React from "react";
import { Collapse, ListItem, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ToggleButtonGroup } from "@material-ui/lab";
import Icon from "../../../UI/Icon/Icon";
import StyledLine from "./StyledLine"
import StyledLabel from "./StyledLabel"
import StyledToggleButton from "./StyledToggleButton"

const useStyles = makeStyles(() => ({
  expandButton: {
    padding: "19px 0px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  toggleGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  svgIcon: {
    overflow: "visible",
  },
}));

const AccessoryGroup = ({
                          expandGroup,
                          setExpandGroup,
                          mainText,
                          onChangeMethod,
                          accessories,
                          currentValue,
                        }) => {
  const classes = useStyles();
  return (
    <div>
      <StyledLine />
      <ListItem className={classes.expandButton} onClick={() => {
        setExpandGroup(!expandGroup);
      }}
      >
        <StyledLabel>{mainText}</StyledLabel>
        {expandGroup ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expandGroup} timeout="auto" unmountOnExit>
        <ToggleButtonGroup
          size="large"
          aria-label="selected accessory"
          value={currentValue}
          exclusive
          className={classes.toggleGroup}
          onChange={onChangeMethod}
        >
          {accessories.map(fileName => (
            <StyledToggleButton value={fileName.id} key={fileName.id}>
              <Icon
                className={classes.svgIcon}
              >
                <img src={fileName.icon} alt="" />
              </Icon>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      </Collapse>
    </div>
  );
};


export default AccessoryGroup;
