import * as Yup from "yup";

export const ProductSchema = Yup.object().shape({
  productName: Yup.string()
    .trim()
    .required('errors.field_required'),
  productShortDescription: Yup.string()
    .max(64, 'errors.max_char_64'),
  categories: Yup.array()
    .min(1, 'errors.field_required'),
  price: Yup.number(),
  compareAtPrice: Yup.number(),
  facebook: Yup.string()
    .max(255, 'errors.max_char_255'),
  twitter: Yup.string()
    .max(255, 'errors.max_char_255'),
  direct: Yup.string()
    .max(255, 'errors.max_char_255'),
  sku: Yup.string()
    .trim()
    .max(100, 'errors.max_char_100'),
  trackQuantity: Yup.number(),
});
