import parse from "csv-parse";
import bulkExportQuery from "./graphql/queries/bulkExport";
import moment from "moment";

/**
 * Get a url for a product's PDP page given a product object
 * @param {String} productId - A product id
 * @param {String} variantId - A variant or option id
 * @param {String} parentVariantId - A parent variant id of the second argument
 * @returns {String} A relative Url to the product's detail page
 */
export default function getPDPUrl(shopId, productId, variantId, parentVariantId) {
  if (variantId && parentVariantId) {
    // Option
    return `/products/${shopId}/${productId}/${parentVariantId}/${variantId}`;
  }

  // Variant
  if (variantId) {
    return `/products/${shopId}/${productId}/${variantId}`;
  }

  return `/products/${shopId}/${productId}`;
}

export const importFiles = ({
  newFiles,
  setIsImportLoading,
  bulkImport,
  shopId,
  viewer,
  persistantSnackbar,
  refetch,
  t,
}) => {
  newFiles.map(file => {
    const output = [];
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onloadend = () => {
      parse(reader.result, {
        trim: true,
        skip_empty_lines: true,
        delimiter: [","],
        columns: [
          "id",
          "parentId",
          "isVisible",
          "imageIds",
          "productTitle",
          "productSubTitle",
          "productShortDescription",
          "productLongDescription",
          "productPurchaseNotes",
          "productIsPublished",
          "facebookShareMessage",
          "twitterShareMessage",
          "directShareMessage",
          "productTags",
          "productBrands",
          "productCategories",
          "relatedProducts",
          "variantAttributeLabel",
          "variantShortTitleOrHex",
          "variantFullTitle",
          "variantSku",
          "variantOriginCountry",
          "variantWidth",
          "variantLength",
          "variantHeight",
          "variantWeight",
          "variantPrice",
          "variantOriginalPrice",
          "variantIventoryInStock",
          "variantCanBackorder",
        ],
      })
        .on("readable", function() {
          let record;

          while ((record = this.read())) {
            output.push(record);
          }
        })
        .on("error", error => {
          alert(error.message);
        })
        .on("end", async () => {
          let data = [];
          let errors = [];

          const parseToBoolean = value => {
            switch (value.toLowerCase()) {
              case "1":
              case "true":
                return true;

              default:
                return false;
            }
          };

          const splitIntoArray = value => {
            let createdArray = value && value !== "" ? value.split(";") : [];
            return createdArray.map(e => e.trim());
          };

          // Check the first line (table header)
          const titleObject = {
            id: "ID",
            parentId: "Parent ID",
            isVisible: "Is Visible",
            imageIds: "Image IDs (separated by semi-column. Priority left to right)",
            productTitle: "Product Title",
            productSubTitle: "Product Subtitle",
            productShortDescription: "Product Short Description",
            productLongDescription: "Product Long Discription",
            productPurchaseNotes: "Product Purchase Notes",
            productIsPublished: "Product Is Published",
            facebookShareMessage: "Facebook Share Message",
            twitterShareMessage: "Twitter Share Message",
            directShareMessage: "Direct Share Message",
            productTags: "Product Tags (separated by semi-column)",
            productBrands: "Product Brands (separated by semi-column)",
            productCategories: "Product Categories (separated by semi-column)",
            relatedProducts: "Related Product IDs (separated by semi-column)",
            variantAttributeLabel: "Variant Attribute Label",
            variantShortTitleOrHex: "Variant Short Title or Hex",
            variantFullTitle: "Variant Full Title",
            variantSku: "Variant SKU",
            variantOriginCountry: "Variant Origin Country",
            variantWidth: "Variant Width",
            variantLength: "Variant Length",
            variantHeight: "Variant Height",
            variantWeight: "Variant Weight",
            variantPrice: "Variant Price",
            variantOriginalPrice: "Variant Original Price",
            variantIventoryInStock: "Variant Items In Stock",
            variantCanBackorder: "Variant Can Be Backordered",
          };

          for (const property in titleObject) {
            const value = titleObject[property];

            if (output[0][property] !== value) {
              return alert(
                `Wrong column names. Please keep them exactly as they appear in the template. Error at column: ${value}`
              );
            }
          }

          // At first we only want to go through all prodcuts, that way we create them all and there is no way we
          // reach a variant that belongs to a product that hasn't been inserted into the data object yet
          output.forEach((row, rowIndex) => {
            // We skip the first line (table header)
            if (rowIndex === 0) {
              return;
            }

            // We only want rows without a parentId - products
            if (row.parentId) {
              return;
            }

            data.push({
              externalId: row.id.trim(),
              isVisible: parseToBoolean(row.isVisible.trim()),
              title: row.productTitle.trim(),
              subtitle: row.productSubTitle.trim(),
              shortDescription: row.productShortDescription.trim(),
              longDescription: row.productLongDescription.trim(),
              purchaseNotes: row.productPurchaseNotes.trim(),
              isPublished: parseToBoolean(row.productIsPublished.trim()),
              facebookShareMsg: row.facebookShareMessage.trim(),
              twitterShareMsg: row.twitterShareMessage.trim(),
              directShareMsg: row.directShareMessage.trim(),
              tags: splitIntoArray(row.productTags.trim()),
              brands: splitIntoArray(row.productBrands.trim()),
              categories: splitIntoArray(row.productCategories.trim()),
              relatedProducts: splitIntoArray(row.relatedProducts.trim()),
              imageIds: splitIntoArray(row.imageIds.trim()),
              variants: [],
            });
          });

          // Then we go through all the rows again, this time inserting only the variants in the variants array of the
          // product they belong to
          output.forEach((row, rowIndex) => {
            // We skip the first line (table header)
            if (rowIndex === 0) {
              return;
            }

            // We only want rows with a parentId - variants
            if (!row.parentId) {
              return;
            }

            // Let's find the product index that this variant belongs to
            const productIndex = data.findIndex(product => product.externalId === row.parentId);

            if (productIndex === -1) {
              return errors.push(
                `Could not find parentId ${row.parentId} of variant ${row.id}. Aborting import!`
              );
            }

            data[productIndex].variants.push({
              externalId: row.id.trim(),
              isVisible: parseToBoolean(row.isVisible.trim()),
              attributeLabel: row.variantAttributeLabel.trim(),
              attributeShortTitle: row.variantShortTitleOrHex.trim(),
              fullTitle: row.variantFullTitle.trim(),
              sku: row.variantSku.trim(),
              originCountry: row.variantOriginCountry.trim(),
              width: parseFloat(row.variantWidth.trim()),
              length: parseFloat(row.variantLength.trim()),
              height: parseFloat(row.variantHeight.trim()),
              weight: parseFloat(row.variantWeight.trim()),
              price: parseFloat(row.variantPrice.trim()),
              originalPrice: parseFloat(row.variantOriginalPrice.trim()),
              inventoryInStock: parseInt(row.variantIventoryInStock.trim()),
              canBackorder: parseToBoolean(row.variantCanBackorder.trim()),
              imageIds: splitIntoArray(row.imageIds.trim()),
            });
          });

          if (errors.length) {
            alert(errors[0]);

            return console.error(errors);
          }

          setIsImportLoading(true);

          try {
            const result = await bulkImport({
              variables: {
                companyId: viewer.companyId,
                shopId,
                products: data,
              },
            });

            setIsImportLoading(false);

            const { totalInputCount, totalMutatedCount } = result.data.bulkProductImport;

            persistantSnackbar(t('product.products_import_success', {
              count: totalMutatedCount,
              total: totalInputCount,
            }));

            refetch();
          } catch (error) {
            persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
            console.error(error);
            setIsImportLoading(false);
          }
        });
    };
  });
};

export const handleBulkExport = async ({
  shopIds,
  enqueueSnackbar,
  setIsImportLoading,
  apolloClient,
  persistantSnackbar,
  currentShop,
  t,
}) => {
  if (shopIds.length !== 1) {
    enqueueSnackbar(t('product.please_select_one_shop'), { variant: "error" });
    return;
  }

  setIsImportLoading(true);

  let data = null;

  try {
    ({ data } = await apolloClient.query({
      query: bulkExportQuery,
      variables: {
        shopId: shopIds[0],
      },
      fetchPolicy: "network-only",
    }));

    setIsImportLoading(false);

    const { products } = data.bulkProductExport;

    data = products || [];
  } catch (error) {
    persistantSnackbar(error.toString().replace("GraphQL error:", ""), "error");
    console.error(error);

    setIsImportLoading(false);
  }

  const NEWLINE = "\n";
  const noData = "";
  const arrayDelimeter = ";";
  let csvString = `ID,Parent ID,Is Visible,Image IDs (separated by semi-column. Priority left to right),Product Title,Product Subtitle,Product Short Description,Product Long Discription,Product Purchase Notes,Product Is Published,Facebook Share Message,Twitter Share Message,Direct Share Message,Product Tags (separated by semi-column),Product Brands (separated by semi-column),Product Categories (separated by semi-column),Related Product IDs (separated by semi-column),Variant Attribute Label,Variant Short Title or Hex,Variant Full Title,Variant SKU,Variant Origin Country,Variant Width,Variant Length,Variant Height,Variant Weight,Variant Price,Variant Original Price,Variant Items In Stock,Variant Can Be Backordered${NEWLINE}`;

  data?.forEach(
    ({
       externalId: productExternalId,
       isVisible,
       title,
       subtitle,
       shortDescription,
       longDescription,
       purchaseNotes,
       isPublished,
       facebookShareMsg,
       twitterShareMsg,
       directShareMsg,
       tags,
       brands,
       categories,
       relatedProducts,
       imageIds: productImageIds,
       variants,
     }) => {
      csvString += `${productExternalId},${noData},${isVisible},${productImageIds.join(
        arrayDelimeter
      )},${title},${subtitle},${shortDescription},${longDescription},${purchaseNotes},${isPublished},${facebookShareMsg},${twitterShareMsg},${directShareMsg},${tags.join(
        arrayDelimeter
      )},${brands.join(arrayDelimeter)},${categories.join(arrayDelimeter)},${relatedProducts.join(
        arrayDelimeter
      )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${NEWLINE}`;

      variants.forEach(
        ({
           externalId: variantExternalId,
           isVisible,
           attributeLabel,
           attributeShortTitle,
           fullTitle,
           sku,
           originCountry,
           width,
           length,
           height,
           weight,
           price,
           originalPrice,
           inventoryInStock,
           canBackorder,
           imageIds: variantImageIds,
         }) => {
          csvString += `${variantExternalId},${productExternalId},${isVisible},${variantImageIds.join(
            arrayDelimeter
          )},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${noData},${attributeLabel},${attributeShortTitle},${fullTitle},${sku},${originCountry},${width},${length},${height},${weight},${price},${originalPrice},${inventoryInStock},${canBackorder}${NEWLINE}`;
        }
      );
    }
  );

  const download = (content, mimeType, filename) => {
    let a = document.createElement("a");
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
  };

  download(
    csvString,
    "text/csv",
    `${t('product.products')} - ${currentShop.name} - ${moment().format("MM/DD/YYYY hh:mm:ss")}.csv`
  );
};
