import React from "react";
import styled from "styled-components";
import FormInputMiniNumber from "../../../UI/Form/FormInputMiniNumber";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import config from "../../../config";
import IconButtons from "../../../UI/IconButtons";
import { useTranslation } from "react-i18next";

const filesBaseUrl = config.PUBLIC_FILES_BASE_URL;

const WrapperMediaItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 24px 0;
  
  .image {
    width: 150px;
    height: 150px;
    margin: 0 27px;
  }
  
  .feature {
    flex-grow: 1;
  }
`

const WrapperIMG = styled.img`
  max-height: 150px;
  max-width: 150px;
`

const MediaItem = ({
   data,
   handleChangeOrder,
   handleSaveOrder,
   valueForHighestPriority,
   numberOfImagesWithHighestPriority,
   handleDeleteImage
}) => {
  const { t } = useTranslation();

  let imageSrc = data.URLs.small;
  const isValidImage = imageSrc !== String(null);

  if (isValidImage) {
    imageSrc = `${filesBaseUrl}${imageSrc}`;
  }

  return (
    <WrapperMediaItem>
      <FormInputMiniNumber
        value={data.priority}
        onChange={handleChangeOrder}
        onBlur={handleSaveOrder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSaveOrder();
          }
        }}
      />

      <div className='image'>
        {
          isValidImage ? <WrapperIMG src={imageSrc} alt='img'/> : null
        }
      </div>

      <div className='feature'>
        {
          valueForHighestPriority === data.priority && numberOfImagesWithHighestPriority === 1 &&
          <PageStatusMessage>{t('product.default')}</PageStatusMessage>
        }
      </div>

      <IconButtons
        type='deleteImage'
        handleClick={handleDeleteImage}
      />
    </WrapperMediaItem>
  );
}

export default MediaItem;
