import React, { useContext } from "react";
import {TextField} from '@material-ui/core';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';
import InputErrorMessage from "./InputErrorMessage";
import { LanguageContext } from "../../package/src/context/LanguageContext";
import { useTranslation } from "react-i18next";

const errorCss = css`
  background-color: ${colors.redLight10};
  border: 1px solid ${colors.redLight};

  & .Mui-focused {
    background-color: transparent;
  }
`

const removeScrollbar = css`
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const StyledTextField = styled(TextField)`
  background-color: ${colors.inputNormal};
  border-radius: 21px;
  height: ${props => props.multiline ? 'auto' : '42px'};
  width: 100%;
  margin-bottom: 24px;
  
  & .MuiInputBase-root {
    height: ${props => props.multiline ? 'auto' : '42px'};
    overflow-y: scroll;
    max-height: ${props => props.multiline ? 'auto' : '200px'};
    padding-right: 22px;

    input {
      padding: 0 10px 0 22px;
    }
    
    textarea {
      padding-left: 8px;
      ${removeScrollbar}
    }
    
    ${removeScrollbar}
  }

  & .Mui-focused {
    background-color: ${colors.inputFocus};
    border-radius: 21px;
  }

  fieldset {
    border: none;
  }
  
  ${props => props.error && errorCss}

  ${props => props.custommargin && css`
    margin: ${props.custommargin};
  `}

  ${props => props.isrtl === 'true' && css`
    & .MuiInputBase-root {
      padding-right: 0;
      padding-left: 22px;
    }

    input {
      padding: 0 22px 0 10px !important;
    }

    textarea {
      padding-left: 0;
      padding-right: 8px;
    }
  `}
`

const FormInput = ({error, margin, ...other}) => {
  const { isRtl } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <>
      <StyledTextField
        error={Boolean(error)}
        custommargin={margin}
        {...other}
        variant="outlined"
        isrtl={String(isRtl)}
      />
      {
        error ?
          <InputErrorMessage>{t(error.message)}</InputErrorMessage>
          : null
      }
    </>
  );
}

export default FormInput;
