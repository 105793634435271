import styled from "styled-components";

export const PendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .statusText {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin: 0;
  }
  
  .statusIcon {
    width: 75px;
    height: 75px;
  }

  .preloader {
    width: 100px;
    margin-bottom: 26px;
  }

  .statusError {
    font-family: 'Fredoka', sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #ff0000;
    margin: 0;
  }
`;

export const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BillingCardsBox = styled.div`
  width: 100%;
  height: 329px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E9E9E9;
    border-radius: 8px;
  }
`;
