import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Auth } from "aws-amplify";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";

let sharedClient;
let token;

/**
 * @summary Set the access token that GraphQL requests will use in the Authorization header
 * @param {String} value New token value
 * @return {undefined}
 */
export function setAccessToken(value) {
  const previousToken = token;
  if (value) token = value;

  // "Resets your entire store by clearing out your cache and then re-executing all of your active queries.
  // This makes it so that you may guarantee that there is no data left in your store from a time
  // before you called this method."
  //
  // We do this because we have effectively switched users here. We don't want data from the previous user
  // (or the previous non-authenticated queries) to be kept.
  if (previousToken !== token) {
    if (sharedClient) sharedClient.resetStore();
  }
}

/**
 * @summary Sets the Authorization header for all GraphQL requests done
 *   through simpleClient.
 * @param {Object} client graphql.js client instance
 * @returns {undefined}
 */
export function setSimpleClientTokenHeader(client) {
  if (token) {
    client.headers({ "jwt-token": token });
  } else {
    client.headers({});
  }
}

/**
 * @name initApollo
 * @summary Initializes Apollo Client
 * @returns {Object} New ApolloClient
 */
export default function initApollo({ graphqlApiUrl }) {
  if (sharedClient) return sharedClient;

  // const authenticationLink = new ApolloLink((operation, forward) => {
  //   if (typeof token === "string") {
  //     operation.setContext( () => ({
  //       headers: {
  //         "jwt-token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  //       },
  //     }));
  //   }

  //   return forward(operation);
  // });

  // Codemonk91: we replaced the `new ApolloLink((operation, forward)` with the
  // below `setContext` because it allows for asynchronous fetches (which is
  // needed for fetching the jwt token from the amplify functions). This means
  // we changed the logic from the initial RC way which was to invoke
  // `setAccessToken` inside useAuth. Instead, this will fetch the jwt token
  // from amplify and add it to the header of every request. We might want to
  // consider caching if this harms performance.
  // `setAccessToken` is probably useless now but we kept it just in case.
  const authenticationLink = setContext(async (_, { headers }) => {
    const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    setAccessToken(token);

    return {
      headers: {
        ...headers,
        "jwt-token": token,
      },
    };
  });

  const httpLink = new HttpLink({ uri: graphqlApiUrl });

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError.statusCode === 401) Auth.signout();
  });

  const standardLink = ApolloLink.from([authenticationLink, httpLink, logoutLink]);

  sharedClient = new ApolloClient({
    link: standardLink,
    cache: new InMemoryCache(),
  });
  return sharedClient;
}
