import React from "react";
import styled, {css} from "styled-components";
import { colors } from '../../../constants';
import { useHistory } from "react-router-dom";
import Wait from "./components/Wait";
import Completed from "./components/Completed";
import Billing from "./components/Billing";

const messageContainerCss = css`
  border: 4px solid #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 522px;
  height: 463px;
  background: ${colors.white6};
  border-radius: 20px;
  padding: 26px;
  position: relative;
  
  ${props => props.isMessageContainer && messageContainerCss};

  @media all and (max-width: 960px) {
    width: 100%;
  }
`;

const Payment = ({
  paymentStatus,
  handleProceedPayment,
  setCurrentTab,
  companyId,
  processing,
  paymentError,
  paymentPending,
  isFreePackage,
}) => {
  const history = useHistory();

  const handleCreateShop = () => {
    setCurrentTab('info');
    history.push('/shops/create');
  }

  const getComponent = () => {
    if (paymentError) {
      return <Wait
        paymentError={paymentError}
      />;
    }

    if (processing) {
      return <Wait />;
    }

    switch (paymentStatus) {
      case 'pending': return <Wait
        paymentPending={paymentPending}
      />;
      case 'open': return <Completed
        handleCreateShop={handleCreateShop}
      />;
      default: return <Billing
        companyId={companyId}
        handleProceedPayment={handleProceedPayment}
        isFreePackage={isFreePackage}
      />;
    }
  }

  return (
    <Wrapper isMessageContainer={
      paymentStatus === 'pending' ||
      paymentStatus === 'open' ||
      processing ||
      paymentError
    }>
      { getComponent() }
    </Wrapper>
  );
}

export default Payment;
