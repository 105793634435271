import React from "react";
import { PendingContainer } from "../styles";
import PreloaderAnimated from "../../../../UI/Preloader";
import FormText from "../../../../UI/Form/FormText";
import { useTranslation } from "react-i18next";

const Wait = ({paymentPending, paymentError}) => {
  const { t } = useTranslation();

  const getHeadingText = () => {
    if (paymentPending) {
      return t('payment.payment_not_completed');
    }

    if (paymentError) {
      return t('payment.payment_error');
    }

    return t('payment.wait_accept_payment');
  }

  const getText = () => {
    if (paymentPending) {
      return t('payment.payment_in_progress_message');
    }

    if (paymentError) {
      return t('payment.payment_error_message');
    }

    return '';
  }

  return (
    <PendingContainer>
      <div className='preloader'>
        <PreloaderAnimated />
      </div>
      <p className='statusText'>
        { getHeadingText() }
      </p>
      <FormText>
        { getText() }
      </FormText>
    </PendingContainer>
  );
};

export default Wait;
