import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';
import { colors } from '../../../constants';
import ProductMediaItem from "./ProductMediaItem";
import { useSnackbar } from "notistack";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import updateMediaRecordPriorityMutation from "../graphql/mutations/updateMediaRecordPriorityMutation";
import FormNote from "../../../UI/Form/FormNote";
import MediaUploader from "./MediaUploader";
import ProductQuery from "../graphql/queries/product";
import archiveMediaRecordMutation from "../graphql/mutations/archiveMediaRecordMutation";
import { linkMediaRecordsToProductMutation, linkMediaRecordsToVariantMutation } from "./MediaGallerySelector/mutations";
import { RemoveMedia } from "./Dialogs";
import MediaGallerySelectorDialog from "./MediaGallerySelector/MediaGallerySelectorDialog";
import Dialog from "../../../UI/Dialog/Dialog";
import { useTranslation } from "react-i18next";

const WrapperColumns = styled.div`
  display: flex;
  gap: 32px;
  
  p {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.black};
    margin: 0;
  }
`

const ProductMediaGallery = ({
   productId,
   shopId,
   dialogName,
   setDialogName,
   dialogOpen,
   setDialogOpen,
}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [media, setMedia] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { data: mediaData, refetch: refetchMedia } = useQuery(ProductQuery, {
    variables: {
      productId,
      shopId,
    },
    skip: !productId,
  });

  useEffect(() => {
    if (mediaData) {
      const rawMedia = mediaData.product.media;
      setMedia(rawMedia.sort((a, b) => a.priority - b.priority));
    }
  }, [mediaData])

  const handleDeleteImage = useCallback((id) => {
    setDialogName('removeMedia');
    setSelectedImageId(id);
    setDialogOpen(true);
  }, [])

  const handleRemoveMedia = useCallback(() => {
    setDialogOpen(false);
    apolloClient.mutate({
      mutation: archiveMediaRecordMutation,
      variables: {
        input: {
          mediaRecordId: selectedImageId,
          shopId,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        archiveMediaRecord: {
          id: selectedImageId,
          __typename: "MediaRecord",
          mediaRecord: null,
        },
      },
    })
      .then(() => {
        refetchMedia();
      })
      .catch((e) => {
        enqueueSnackbar(t('product.unable_remove_media'), {variant: 'error'});
        console.log(e.message);
      })
  }, [selectedImageId, shopId, refetchMedia, t])

  const linkMediaMutations = useCallback((ids, variantId) => {
    if (variantId) {
      return apolloClient.mutate({
        mutation: linkMediaRecordsToVariantMutation,
        variables: {
          input: {
            shopId,
            productId,
            variantId,
            mediaRecordIds: ids,
          },
        },
      });
    } else {
      return apolloClient.mutate({
        mutation: linkMediaRecordsToProductMutation,
        variables: {
          input: {
            shopId,
            productId,
            mediaRecordIds: ids,
          },
        },
      });
    }
  }, [shopId, productId])

  const linkMedia = useCallback((ids) => {
    setIsUploading(true);
    closeDialog();
    const variantId = null; // todo

    linkMediaMutations(ids, variantId)
      .then(res => {
        const output = variantId ? 'linkMediaRecordsToVariant' : 'linkMediaRecordsToProduct';
        const data = res?.data?.[output];
        if (data) {
          enqueueSnackbar(data, { variant: "success" });
          refetchMedia();
        }
      })
      .catch(e => {
        enqueueSnackbar(e.message.replace("GraphQL error:", ""), { variant: "error" });
        console.log(e.message);
      })
      .finally(() => setIsUploading(false))
  }, [linkMediaMutations, refetchMedia, t])

  const handleSetMedia = useCallback(({ id, field, value }) => {
    setMedia(prev => {
      return prev.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item;
      });
    });
  }, [])

  const handleChangeOrder = useCallback((id, value) => {
    let newValue = value;
    if (value < 0) {
      newValue = 0;
    } else if (value > 99) {
      newValue = 99;
    }

    handleSetMedia({
      id,
      field: 'priority',
      value: newValue,
    });
  }, [])

  const handleSaveOrder = useCallback((item) => {
    let value = Number(item.priority);
    if (!value) {
      value = 0;
    }

    const oldValue = media.find(i => i._id === item._id).priority;
    if (value === oldValue) {
      return;
    }

    apolloClient.mutate({
      mutation: updateMediaRecordPriorityMutation,
      variables: {
        input: {
          mediaRecordId: item._id,
          priority: Number(value),
          shopId,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(t('product.updated_image_order_success'), { variant: "success", });
        refetchMedia();
      })
      .catch((e) => {
        enqueueSnackbar(t('product.updated_image_order_error'), {variant: 'error'});
        console.log(e.message);
      })
  }, [media, refetchMedia, t])

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const getDialogComponent = () => {
    switch (dialogName) {
      case 'removeMedia': return <RemoveMedia
        confirmAction={handleRemoveMedia}
        declineAction={closeDialog}
      />;
      case 'gallery': return <MediaGallerySelectorDialog
        shopId={shopId}
        handleSelect={linkMedia}
        existingIds={media.map(item => item._id)}
      />;
      default: return null;
    }
  }

  // Determine the lowest rank (highest prio) inputted by user
  let valueForHighestPriority = +Infinity;
  media.forEach(m => {
    // Don't take variants into account. This is the Product Media Gallery (this was changed)
    // if (m.variantId) return;

    valueForHighestPriority =
      m.priority < valueForHighestPriority ? m.priority : valueForHighestPriority;
  });

  // Determine how many images have the lowest rank (highest prio)
  let numberOfImagesWithHighestPriority = 0;
  media.forEach(m => {
    // Don't take variants into account. This is the Product Media Gallery (this was changed)
    // if (m.variantId) return;

    m.priority === valueForHighestPriority && numberOfImagesWithHighestPriority++;
  });

  return (
    <>
      {
        numberOfImagesWithHighestPriority > 1 &&
        <FormNote><b>{t('product.note')}: </b>{t('product.image_note_message')}</FormNote>
      }
      {
        media.length ?
          <WrapperColumns>
            <p>{t('product.order')}</p>
            <p>{t('product.media')}</p>
          </WrapperColumns> : null
      }
      {
        media.map(item => <ProductMediaItem
            key={item._id}
            data={item}
            handleChangeOrder={(e) => handleChangeOrder(item._id, e.target.value)}
            handleSaveOrder={() => handleSaveOrder(item)}
            valueForHighestPriority={valueForHighestPriority}
            numberOfImagesWithHighestPriority={numberOfImagesWithHighestPriority}
            handleDeleteImage={() => handleDeleteImage(item._id)}
          />)
      }

      <MediaUploader
        shopId={shopId}
        productId={productId}
        priority={(media.length + 1)}
        enqueueSnackbar={enqueueSnackbar}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
        refetchMedia={refetchMedia}
      />

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        {getDialogComponent()}
      </Dialog>
    </>
  );
}

export default ProductMediaGallery;
