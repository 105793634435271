import React from 'react';
import PropTypes from 'prop-types';
import {Tabs} from '@material-ui/core';
import styled, {css} from 'styled-components';
import {colors} from '../../constants';

const smallCss = css`
  margin-bottom: 24px;
  
  & .MuiButtonBase-root {
    font-size: 18px;
    min-width: ${props => props.minwidth ? props.minwidth : '120px'};
    padding: 0 12px 12px;
  }

  & .MuiTabs-scroller {
    border-bottom: 1px solid #E9E9E9;
  }

  .MuiTabs-indicator {
    height: 6px;
  }
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  
  & .MuiButtonBase-root {
    font-family: 'Fredoka', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: ${colors.textGrey};
    opacity: 1;
    padding-bottom: 24px;
    min-width: ${props => props.minwidth ? props.minwidth : ''};
    
    &.Mui-selected {
      color: ${colors.black};
    }
  }
  
  .MuiTabs-indicator {
    background-color: ${colors.blue};
    height: 8px;
    border-radius: 8px;
  }
  
  ${props => props.issmall === 'true' && smallCss}
`

const CustomTabs = ({children, issmall, minwidth, ...other}) => {
  return (
    <StyledTabs
      issmall={String(issmall)}
      minwidth={minwidth}
      {...other}
    >
      {children}
    </StyledTabs>
  );
}

CustomTabs.propTypes = {
  issmall: PropTypes.bool,
}

export default CustomTabs;
