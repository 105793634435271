import React from "react";
import { Tooltip } from "@material-ui/core";

const CustomTooltip = ({title, isOpen, position, isEmpty}) => {
  if (isEmpty) {
    return null;
  }

  return (
    <Tooltip
      open={isOpen}
      placement="top"
      PopperProps={{
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: position.y,
            left: position.x,
            right: position.x,
            bottom: position.y,
            width: 0,
            height: 0,
          }),
        },
      }}
      style={{
        maxHeight: 200
      }}
      // onOpen={() => handleOpen(true)}
      // onClose={() => handleOpen(false)}
      title={title}
      arrow
    >
      <div />
    </Tooltip>
  );
};

export default CustomTooltip;
