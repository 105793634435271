import React from "react";
import CustomAmplifyAuthenticator from "../CustomAmplifyAuthenticator";
import { useTranslation } from "react-i18next";

const AuthenticationPage = () => {
  const { t } = useTranslation();
  // const url = process.env.REACT_APP_PUBLIC_UI_URL;
  //
  // useEffect(() => {
  //   window.location.href = `${url}/registration/login`;
  // }, [])
  // return null;
  return <CustomAmplifyAuthenticator
    signinHeader={t('common.sign_in')}
    signupHeader={t('common.create_account')}
  />;
};

export default AuthenticationPage;
