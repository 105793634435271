export const Shop = `
  _id
  name
  description
  slug
  createdAt
  updatedAt
  defaultCatalogSorting
  active
  status
  shopLogoUrls {
    primaryShopLogoUrl
  }
  getShopCategory {
    _id,
    name
  }
  emails {
    address
  }
  phoneNumbers
  facebook
  instagram
  twitter
  eCommerceEnabled
  linkToExternalWebsite
  company {
    _id
    name
  }
  subscription {
    preOverride {
      rate
      commissionPercentage
    }
    postOverride {
      rate
      commissionPercentage
    }
    billingPeriodInDays
  }
`;

export const ShopAfterCreation = `
  _id
  name
  description
  slug
  createdAt
  updatedAt
`;

export const ShopCategory = `
  _id
  name
  description
  slug
  createdAt
  updatedAt
`;

export const MerchantRequest = `
  _id
  reason
  type
  state
  changeFrom
  changeTo
  fromShopCategory {
    name
  }
  toShopCategory {
    name
  }
`;
