import gql from "graphql-tag";

const getSimpleInventory = gql`
  query simpleInventory($shopId: ID!, $productConfiguration: ProductConfigurationInput!) {
    simpleInventory(shopId: $shopId, productConfiguration: $productConfiguration) {
      isEnabled
      canBackorder
      inventoryInStock
    }
  }
`;

export default getSimpleInventory;
