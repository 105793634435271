/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

    const awsmobile = {
    "aws_project_region": "eu-central-1",    
    "aws_cognito_region": "eu-central",
    "aws_user_pools_id": "eu-central-1_ib5s9Lhqy",
    "aws_user_pools_web_client_id": "4fit78nsggguk82u3912s543eu",
    "oauth": {},
    "cookieStorage": {
        "domain": ".beta.wondersouq.com",
        "secure": false,
        "path": "/",
    },
    "aws_user_files_s3_bucket": "wondersouq-beta",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;

