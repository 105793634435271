import * as THREE from "three";
import { STREET_HEIGHT, STREET_WIDTH } from "../constants/constants";

function moveUpDown(object, vec, direction) {
  const vec2 = vec.clone();
  const quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2);
  vec2.applyQuaternion(quaternion);
  vec2.normalize();
  vec2.multiplyScalar(0.04 * direction);
  object.position.add(vec2);
}

export default class CityBuilder {

  constructor() {
    this.connections3dVectors = new Map();
    this.checkedStreetUuids = [];
    this.checkedConnectionUuids = [];
  }

  getObjectByUuid(streets, uuid) {
    for (const street of streets) {
      if ("userData" in street) {
        if (street.userData.uuid === uuid) {
          return street;
        }
      } else {
        if (street.uuid === uuid) {
          return street;
        }
      }
    }
    return null;
  }

  getStreetByName(streets, name) {
    for (const street of streets) {
      if (street.name === name) {
        return street;
      }
    }
    return null;
  }

  buildCityFromConnectionId(connectionUuid, connects, streets, streets3d, startStreet3d) {
    if (connectionUuid === null) {
      return;
    }
    let conPosition = new THREE.Vector3(0.0, 0.0, 0.0);
    if (this.checkedConnectionUuids.includes(connectionUuid)) {
      return;
    }
    this.checkedConnectionUuids.push(connectionUuid);
    const currentCon = this.getObjectByUuid(connects, connectionUuid);
    if (startStreet3d !== null) {
      const street = this.getObjectByUuid(streets, startStreet3d.userData.uuid);
      const isBegin = street.isConnectionHasBeginPoint(currentCon);
      let streetVec = street.vec();
      streetVec.normalize().multiplyScalar(!isBegin ? (-STREET_HEIGHT / 2 - STREET_WIDTH / 2) : (STREET_HEIGHT / 2 + STREET_WIDTH / 2));
      let pos = startStreet3d.position.copy();
      conPosition = pos.add(streetVec);
    }
    this.connections3dVectors[connectionUuid] = conPosition;
    for (const streetUuid of currentCon.streetUuids) {
      if (this.checkedStreetUuids.includes(streetUuid)) {
        continue;
      }
      this.checkedStreetUuids.push(streetUuid);
      const street = this.getObjectByUuid(streets, streetUuid);
      if (!street.isRegular) {
        return;
      }
      const isBegin = street.isConnectionHasBeginPoint(currentCon);
      let streetVec = street.vec();
      streetVec.normalize().multiplyScalar(!isBegin ? (-STREET_HEIGHT / 2 - STREET_WIDTH / 2) : (STREET_HEIGHT / 2 + STREET_WIDTH / 2));
      const street3d = this.getObjectByUuid(streets3d, streetUuid);
      let streetPos = conPosition.clone().add(streetVec);
      street3d.position.set(streetPos.x, streetPos.y, streetPos.z);
      street3d.updateMatrix();
      let nextTestUuid = isBegin ? street.endPoint.connectionUuid : street.beginPoint.connectionUuid;
      this.buildCityFromConnectionId(nextTestUuid, connects, streets, streets3d, startStreet3d);
    }
  }

  buildStaticStreets(streets, streets3d, streetsNames3d, streetCategories3d) {
    const telecomStreet = this.getStreetByName(streets, 'Telecom showroom');
    const telecom3dStreet = this.getObjectByUuid(streets3d, telecomStreet.uuid);
    const telecom3dName = this.getObjectByUuid(streetsNames3d, telecomStreet.uuid);
    const telecomCategory3d = this.getObjectByUuid(streetCategories3d, telecomStreet.uuid);
    telecom3dStreet.position.set(0.614, -0.6, 0.0);
    telecom3dName.position.set(
      telecom3dStreet.position.x + telecom3dName.userData.offsetX,
      telecom3dStreet.position.y + telecom3dName.userData.offsetY,
      0.01,
    );
    if (telecomStreet.hasCategory()) {
      const vec = telecomStreet.vec();
      moveUpDown(telecom3dName, vec, 0.8);
      telecomCategory3d.position.set(
        telecom3dStreet.position.x + telecomCategory3d.userData.offsetX,
        telecom3dStreet.position.y + telecomCategory3d.userData.offsetY,
        0.01,
      );
      moveUpDown(telecomCategory3d, vec, -0.8);
    }
  }

  setRegularStreetNamesPlacement(streets, streets3d, streetsNames3d, streetCategories3d) {
    for (const name of streetsNames3d) {
      const street = this.getObjectByUuid(streets, name.userData.uuid);
      if (!street.isRegular) {
        continue;
      }
      const street3d = this.getObjectByUuid(streets3d, name.userData.uuid);
      const category3d = this.getObjectByUuid(streetCategories3d, name.userData.uuid);
      const vec = street.vec();
      name.position.set (
        street3d.position.x + vec.x / vec.length() * name.userData.offsetX - vec.y / vec.length() * name.userData.offsetY,
        street3d.position.y + vec.y / vec.length() * name.userData.offsetX + vec.x / vec.length() * name.userData.offsetY,
        0.01);
      if (street.hasCategory()) {
        moveUpDown(name, vec, 0.8);
        category3d.position.set(
          street3d.position.x + vec.x / vec.length() * category3d.userData.offsetX - vec.y / vec.length() * category3d.userData.offsetY,
          street3d.position.y + vec.y / vec.length() * category3d.userData.offsetX + vec.x / vec.length() * category3d.userData.offsetY,
          0.01);
        moveUpDown(category3d, vec, -0.8);
      }
    }
  }

  buildCity(connects, streets, streets3d, streetsNames3d, streetCategories3d) {
    if (connects.length === 0)
      return;
    this.buildCityFromConnectionId(connects[0].uuid, connects, streets, streets3d, null);
    this.setRegularStreetNamesPlacement(streets, streets3d, streetsNames3d, streetCategories3d);
    this.buildStaticStreets(streets,  streets3d, streetsNames3d, streetCategories3d);
  }
}
