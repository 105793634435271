import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@material-ui/core";
import styled from "styled-components";
import FormCard from "../../../UI/Form/FormCard";
import FormHeader from "../../../UI/Form/FormHeader";
import FormText from "../../../UI/Form/FormText";
import PackageCard from "./packages/PackageCard";
import InputLabel from "../../../UI/Form/InputLabel";
import Payment from "../../../package/src/Payment";
import Button from "../../../UI/Button";
import DialogButtons from "../../../UI/Dialog/DialogButtons";
import { useApolloClient } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getAllPackages } from "../../subscription-packages/graphql/queries";
import PageStatusMessage from "../../../UI/other/PageStatusMessage";
import Dialog from "../../../UI/Dialog/Dialog";
import DialogMessages from "../../../UI/Dialog/DialogMessages";
import StyledButton from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)`
  max-width: 1048px;
`

const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
  
  @media all and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`

const DetailedPlan = styled.div`
  display: flex;
  gap: 18px;

  @media all and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .paymentColumn {
      width: 100%;
    }
  }
`

const SubscriptionTab = ({
  setCurrentTab,
  viewer,
  selectedPackageId,
  setSelectedPackageId,
  packageId,
  paymentStatus,
  handleProceedPayment,
  openId,
  shopId,
  processing,
  showOnlyPackageId,
  paymentError,
  paymentPending,
}) => {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const companyId = viewer.companyId;
  const godmode = viewer.type === 'godmode';
  const hasActive = packages.some(item => item.isActive);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    setLoading(true);

    apolloClient.query({
      query: getAllPackages,
      fetchPolicy: "network-only",
    })
      .then(res => {
        const allPackages = res.data.subscriptionPackages.nodes;

        setPackages(
          allPackages
            .filter(item => {
              const filter1 = godmode || item.isVisibleToMerchants;

              let filter2 = true;

              if (showOnlyPackageId) {
                filter2 = item._id === packageId || item._id === showOnlyPackageId;
              }

              return filter1 && filter2;
            })
            .sort((a, b) => a.baseRate - b.baseRate)
            .map(item => ({
              id: item._id,
              title: item.title,
              currency: item.currency === 'USD-id' ? '$' : '', // todo
              subtitle: 'Subtitle', // todo
              baseRate: item.baseRate,
              duration: item.duration,
              isActive: item._id === packageId,
              description: item.description,
            }))
        );
      })
      .catch((e) => {
        enqueueSnackbar(t('snackbar.common_error'), {variant: 'error'});
        console.log(e.message);
      })
      .finally(() => setLoading(false))
  }, [companyId, packageId, showOnlyPackageId]);

  const handlePackageCardClick = (id) => {
    if (openId) {
      setDialogOpen(true);
      return;
    }
    setSelectedPackageId(hasActive, id);
  }

  const packageData = useMemo(() => {
    return packages.find(item => item.id === selectedPackageId);
  }, [packages, selectedPackageId]);

  return (
    <StyledContainer>
      <FormCard>
        {
          selectedPackageId ?
            <>
              <FormHeader noHorizontalRule>{t('subscription.subscription')}</FormHeader>
              <DetailedPlan>
                {
                  packageData ?
                    <div>
                      <InputLabel>{t('subscription.selected_plan')}</InputLabel>
                      <PackageCard
                        data={packageData}
                        isDetailed
                        isActive
                      />
                    </div>
                    : null
                }
                <div className='paymentColumn'>
                  <InputLabel>{t('subscription.payment')}</InputLabel>
                  <Payment
                    companyId={companyId}
                    setCurrentTab={setCurrentTab}
                    paymentStatus={paymentStatus}
                    handleProceedPayment={(paymentMethodId) => handleProceedPayment({
                      hasActive,
                      paymentMethodId,
                    })}
                    processing={processing}
                    paymentError={paymentError}
                    paymentPending={paymentPending}
                    isFreePackage={packageData?.baseRate === 0}
                  />
                </div>
              </DetailedPlan>
              {
                !paymentError && !paymentPending &&
                <DialogButtons>
                  <Button
                    width='135px'
                    mytype='secondary'
                    handleClick={() => setSelectedPackageId(null)}
                  >
                    {t('ui.back')}
                  </Button>
                </DialogButtons>
              }
            </> :
            <>
              <FormHeader noHorizontalRule>{t('subscription.choose_sub_message')}</FormHeader>
              <FormText>{t('subscription.sub_flexible_message')}</FormText>
              <StyledCardsContainer>
                {
                  loading ?
                    <PageStatusMessage>loading...</PageStatusMessage> :
                    packages.map(item => <PackageCard
                        key={item.id}
                        data={item}
                        hasActive={hasActive}
                        isActive={item.isActive}
                        handleClick={() => handlePackageCardClick(item.id)}
                        shopId={shopId}
                        openId={openId}
                      />)
                }
              </StyledCardsContainer>
            </>
        }
      </FormCard>

      <Dialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      >
        <DialogMessages
          title={t('ui.confirm')}
          message={t('subscription.sub_already_exists_q')}
        />
        <DialogButtons justifyButtons='center'>
          <StyledButton
            width='180px'
            handleClick={() => {
              setDialogOpen(false);
              handleProceedPayment({
                hasActive,
                paymentMethodId: null,
              });
            }}
          >
            {t('ui.yes')}
          </StyledButton>
          <StyledButton
            width='180px'
            mytype='secondary'
            handleClick={() => setDialogOpen(false)}
          >
            {t('ui.no')}
          </StyledButton>
        </DialogButtons>
      </Dialog>
    </StyledContainer>
  );
}

export default SubscriptionTab;
