import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import HeaderArea from '../HeaderArea';
import ProfileMenu from '../ProfileMenu';

// Material UI
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography, makeStyles } from "@material-ui/core";
import MenuIcon from "mdi-material-ui/Menu";
import Icon from '../../../UI/Icon';
import svgBack from '../../../icons/chevron-left-black.svg';
import {colors} from '../../../constants';

// Context
import { UIContext } from "../context/CRUDerUIContext";
import { IconSettings } from "../../../icons";
import { StyledIconButton } from "../ProfileMenu/ProfileMenu";
import { useHistory } from "react-router-dom";
import LanguageSwitch from "../LanguageSwitch";

const useStyles = makeStyles(theme => ({
  action: {
    marginLeft: theme.spacing()
  },
  primarySidebarOpen: {
    ...theme.mixins.leadingPaddingWhenPrimaryDrawerIsOpen,
    paddingLeft: 16,
  },
  detailDrawerOpen: {
    ...theme.mixins.trailingPaddingWhenDetailDrawerIsOpen
  },
  title: {
    flex: 1,
    fontFamily: 'Fredoka',
    fontWeight: 500,
    fontSize: 24,
    color: colors.black,
  },
  menuPadding: {
    width: 410,
    flexShrink: 0,
  },
  iconBack: {
    margin: '0 10px 0 -8px',
  }
}));

/**
 * An AppBar for the main content area that provides a place for a title,
 * actions to the right, and a menu button for opening and closing the sidebar drawer menu
 * @param {Object} props Component props
 * @returns {React.Component} A react component
 */
function PrimaryAppBar({ children, title, onBackButtonClick, shouldShowBackButton = true }) {
  const classes = useStyles();
  const history = useHistory();
  const isSettings = history.location.pathname?.indexOf('settings') > -1;
  
  return (
    <UIContext.Consumer>
      {({ isMobile, isDetailDrawerOpen, isPrimarySidebarOpen, onTogglePrimarySidebar }) => {
        const toolbarClassName = classNames({
          // Add padding to the left when the primary sidebar is open,
          // only if we're on desktop. On mobile the sidebar floats over
          // the content like a modal that's docked to either the left
          // or right side of the screen.
          [classes.primarySidebarOpen]: isPrimarySidebarOpen && !isMobile,

          // Add padding to the right when the detail sidebar is open.
          // Omit on mobile as the sidebar will float over content.
          [classes.detailDrawerOpen]: isDetailDrawerOpen && !isMobile
        });

        return (
          <AppBar>
            <Toolbar className={toolbarClassName}>
              <Hidden smDown>
                <div className={classes.menuPadding}/>
              </Hidden>
              <HeaderArea>
                <Hidden mdUp>
                  <IconButton onClick={onTogglePrimarySidebar}>
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                {shouldShowBackButton && onBackButtonClick && (
                  <Icon 
                    onClick={onBackButtonClick} 
                    className={classes.iconBack}
                  >
                    <img src={svgBack} alt=''/>
                  </Icon>
                )}
                <Typography className={classes.title} component="h1" variant="h3">
                  {title}
                </Typography>
                  <div className={classes.action}>{children}</div>
                <LanguageSwitch />
                <StyledIconButton
                  onClick={() => history.push('/settings')}
                  isselected={isSettings.toString()}
                >
                  <IconSettings/>
                </StyledIconButton>
                <ProfileMenu size={40} />
              </HeaderArea>
            </Toolbar>
          </AppBar>
        );
      }}
    </UIContext.Consumer>
  );
}

PrimaryAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  onBackButtonClick: PropTypes.func,
  onToggleDrawerOpen: PropTypes.func,
  shouldShowBackButton: PropTypes.bool,
  title: PropTypes.node
};

export default PrimaryAppBar;
