import React, { useEffect, useState } from "react";
import FormHeader from "../../../UI/Form/FormHeader";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../config";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { stripeAddPaymentMethodMutation } from "../graphql/mutations";
import { useTranslation } from "react-i18next";

const BillingForm = ({ cardId, refetchPayments, closeDialog, companyId }) => {
  const [stripe, setStripe] = useState(null);

  const {enqueueSnackbar} = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);
    setStripe(stripePromise);
  }, [])

  const [stripeAddPaymentMethod] = useMutation(stripeAddPaymentMethodMutation, {
    ignoreResults: true,
    onCompleted() {
      enqueueSnackbar(t('settings.added_payment_method'), { variant: "success" });
      refetchPayments();
      closeDialog();
    },
    onError(error) {
      enqueueSnackbar(error.message.replace("GraphQL error:", ""), { variant: "error" });
      closeDialog();
    },
  });

  const onFinish = async (paymentMethodId, isDefault, error) => {
    if (error) return;

    await stripeAddPaymentMethod({
      variables: {
        input: {
          companyId,
          paymentMethodId,
          isDefault,
        },
      },
    });
  };

  return (
    <>
      <FormHeader>{cardId ? t('settings.edit_card') : t('settings.new_card')}</FormHeader>
      <Elements stripe={stripe}>
        <CheckoutForm
          onFinish={onFinish}
          cardId={cardId}
        />
      </Elements>
    </>
  );
};

export default BillingForm;
